import React from 'react';
import styled from 'styled-components';

import type { EmployeeCount } from '../../components/employee-growth';
import { Container, Header, Title } from '../section';
import type { MixpanelProps } from '../section';

import EmployeesChart from './employees-chart';

const StyledContainer = styled(Container)`
	padding-bottom: 12px;
`;

const StyledHeader = styled(Header)`
	align-items: end;
	display: grid;
	gap: 8px;
	grid-template:
		'title subtitle toggle' min-content
		/ auto 1fr auto;
`;

interface Props extends MixpanelProps {
	readonly domain: [number, number];
	readonly employees: Array<EmployeeCount> | null;
	readonly ticks: Array<number>;
}

/**
 * @deprecated use src/profiles/sections/new-employees/section.tsx instead
 */
export default function EmployeesSection({
	domain,
	employees,
	ticks,
	...props
}: Props) {
	if (!employees || employees.length === 0) {
		return '';
	}

	return (
		<StyledContainer {...props}>
			<StyledHeader>
				<Title>Employees</Title>
			</StyledHeader>
			<EmployeesChart
				domain={domain}
				employees={employees}
				ticks={ticks}
			/>
		</StyledContainer>
	);
}
