import { colors } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { PersonPlusIcon } from '@drivecapital/design-system/icons/human';
import { XOctagonIcon } from '@drivecapital/design-system/icons/system';
import { ClockSnoozeIcon } from '@drivecapital/design-system/icons/time';
import React from 'react';

interface Props {
	readonly label?: string;
	readonly menu?: boolean;
}

export function AssignedChip({ label = 'Assigned', menu }: Props) {
	return (
		<Chip
			background={colors.data.green.layerSubtle}
			color={colors.data.green.textSecondary}
			icon={<PersonPlusIcon />}
			label={label}
			menu={menu}
		/>
	);
}

export function PassedChip({ label = 'Passed', menu }: Props) {
	return (
		<Chip
			background={colors.data.red.layerSubtle}
			color={colors.data.red.textSecondary}
			icon={<XOctagonIcon />}
			label={label}
			menu={menu}
		/>
	);
}

export function SnoozedChip({ label = 'Snoozed', menu }: Props) {
	return (
		<Chip
			background={colors.data.indigo.layerSubtle}
			color={colors.data.indigo.textSecondary}
			icon={<ClockSnoozeIcon />}
			label={label}
			menu={menu}
		/>
	);
}

export function SurfacedChip({ label = 'Unassigned', menu }: Props) {
	return (
		<Chip
			background={colors.data.dust.layerSubtle}
			color={colors.data.dust.textSecondary}
			icon={<PersonPlusIcon />}
			label={label}
			menu={menu}
		/>
	);
}
