import Tooltip from 'react-tooltip';
import styled from 'styled-components';

const UnstyledTooltip = styled(Tooltip)`
	@media (hover: none) {
		&.__react_component_tooltip.show {
			display: none;
		}
	}

	&.__react_component_tooltip.show {
		background: none;
		border: none;
		opacity: 1;
		padding: 0px;
	}
	&.__react_component_tooltip::before {
		content: none;
	}
	&.__react_component_tooltip::after {
		content: none;
	}
`;
export default UnstyledTooltip;
