import { Temporal } from '@js-temporal/polyfill';

import type { EventData } from './';

export function parseEventDate(date: EventData['date']): Temporal.Instant {
	try {
		return Temporal.Instant.from(date);
	} catch {
		const plainDate = Temporal.PlainDate.from(date);

		return Temporal.ZonedDateTime.from({
			timeZone: 'UTC',
			year: plainDate.year,
			month: plainDate.month,
			day: plainDate.day,
			hour: 0,
			minute: 0,
			second: 0,
		}).toInstant();
	}
}

export function formatDate(date: EventData['date']): string {
	return parseEventDate(date)
		.toZonedDateTimeISO('UTC')
		.toPlainDate()
		.toLocaleString('en-Us', {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit',
		});
}
