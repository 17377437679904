import {
	queryOptions,
	useMutation,
	useQuery,
	type UseQueryOptions,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import type { NoteData, ProfileType } from '../notes';
import store from '../store';
import { get, patch, post, remove, updateQueryData } from '../utils/api';
import delay from '../utils/delay';

import { addNote, deleteNote, editNote, loadNotes } from './actions';

const queryKeys = {
	forProfile: (profileId: number, profileType: ProfileType) => [
		profileType,
		profileId,
	],
	forProfileNote: (profileId: number, profileType: ProfileType) => [
		...queryKeys.forProfile(profileId, profileType),
		'notes',
	],
};

function profileNotesQueryOptions(profileId: number, profileType: ProfileType) {
	return queryOptions({
		queryKey: queryKeys.forProfileNote(profileId, profileType),
		queryFn: async ({ signal }) => {
			const notes = await get<ReadonlyArray<NoteData>>(
				`/${profileType}/${profileId}/notes`,
				{
					signal,
				},
			);

			store.dispatch(loadNotes(profileId, notes, profileType));

			return notes;
		},
	});
}

export function useCreateProfileNote(
	profileId: number,
	profileType: ProfileType,
) {
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: async (
			note: Pick<
				NoteData,
				| 'comment'
				| 'contacted_via'
				| 'public'
				| 'raw_comment'
				| 'reminders'
			>,
		) =>
			post<NoteData[]>(`/${profileType}/${profileId}/notes`, {
				body: { ...note },
			}),
		onSuccess: (notes) => {
			updateQueryData(
				profileNotesQueryOptions(profileId, profileType),
				() => [...notes],
			);
			dispatch(addNote(profileId, notes, profileType));
		},
	});
}

export function useDeleteProfileNote(
	profileId: number,
	profileType: ProfileType,
) {
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: async (noteId: number) => remove(`/notes/${noteId}`),
		onSuccess: (_, noteId) => {
			updateQueryData(
				profileNotesQueryOptions(profileId, profileType),
				(notes) => notes.filter((note) => note.id !== noteId),
			);
			dispatch(deleteNote(noteId));
		},
	});
}

export function useProfileNotes(profileId: number, profileType: ProfileType) {
	return useQuery(profileNotesQueryOptions(profileId, profileType));
}

export function useUpdateProfileNote(
	profileId: number,
	profileType: ProfileType,
	artificialDelayMs = 300,
) {
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: async (
			note: Pick<
				NoteData,
				'comment' | 'contacted_via' | 'id' | 'public' | 'raw_comment'
			>,
		) => {
			const startTime = performance.now();
			const response = await patch<NoteData>(`/notes/${note.id}`, {
				body: { ...note },
			});
			const endTime = performance.now();

			if (endTime - startTime < artificialDelayMs) {
				await delay(artificialDelayMs - (endTime - startTime));
			}

			return response;
		},
		onSuccess: (note) => {
			updateQueryData(
				profileNotesQueryOptions(profileId, profileType),
				(notes) =>
					notes.map((existingNote) =>
						existingNote.id !== note.id ? existingNote : note,
					),
			);
			dispatch(editNote(note));
		},
	});
}

export function useFetchPersonEmails(
	profileId: number,
	options?: Pick<UseQueryOptions, 'enabled'>,
) {
	return useQuery({
		queryKey: queryKeys.forProfile(profileId, 'people'),
		queryFn: async ({ signal }) => {
			const emails = await get<{
				personal_email: string | null;
				work_email: string | null;
			}>(`/people/${profileId}/emails`, { signal });

			return emails;
		},
		...options,
		staleTime: 5 * 60 * 1000, // 5 minutes
	});
}
