import { colors, fonts } from '@drivecapital/design-system';
import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
	${fonts.label.label};
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

interface Props {
	readonly children: React.ReactNode;
}

export default function Header({ children }: Props) {
	return <Body>{children}</Body>;
}
