import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { charcoalGray, primaryDriveBlue, sectionBorder } from '../colors';
import type { ViewType } from '../utils/analytics';

export const Container = styled.section`
	background-color: white;
	border: 1px solid ${sectionBorder.string()};
	margin-bottom: 12px;
	padding: 12px 12px 24px;

	p {
		line-height: 1.5em;
	}
`;

export const Header = styled.header`
	align-items: baseline;
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5em;
`;

export const Subtitle = styled.h3`
	color: ${charcoalGray.string()};
	font-size: 1em;
	font-weight: normal;
	margin-left: 1.5ex;
`;

export const Title = styled.h2`
	color: ${primaryDriveBlue.string()};
	font-size: 1.2em;
	font-weight: normal;
`;

export interface MixpanelProps {
	readonly mixpanelIdentifier?: string;
	readonly mixpanelViewType?: ViewType;
}

export type RequiredMixpanelProps = {
	readonly mixpanelIdentifier: string;
	readonly mixpanelViewType: ViewType;
};

interface Props extends MixpanelProps {
	readonly children?: ReactNode;
	readonly subtitle?: ReactNode;
	readonly title?: string;
}

const Section = ({
	children,
	subtitle,
	title,
	...props
}: Props & React.HTMLAttributes<HTMLElement>): JSX.Element => {
	const section = (
		<React.Fragment>
			{title && (
				<Header>
					<Title>{title}</Title>
					{subtitle && <Subtitle>{subtitle}</Subtitle>}
				</Header>
			)}
			{children}
		</React.Fragment>
	);

	return <Container {...props}>{section}</Container>;
};

export default Section;
