import {
	infiniteQueryOptions,
	queryOptions,
	useInfiniteQuery,
	useMutation,
	useQuery,
	type UseQueryOptions,
} from '@tanstack/react-query';

import { get, patch, post, queryClient } from '../utils/api';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import type {
	EntityType,
	TravelCompanyRecommendation,
	TravelRecommendationStrategy,
	TravelUser,
} from './types';

const travelFeatureQueryKeys = {
	topLevel: () => ['travel'] as const,
	recommendation: (
		userId: number,
		location: string,
		recommendationStrategy: TravelRecommendationStrategy,
		entityId: number,
		entityType: EntityType,
	) =>
		[
			...travelFeatureQueryKeys.topLevel(),
			'recommendation',
			userId,
			location,
			recommendationStrategy,
			entityId,
			entityType,
		] as const,
	recommendations: (
		userId: number,
		location: string,
		recommendationStrategy: TravelRecommendationStrategy,
	) =>
		[
			...travelFeatureQueryKeys.topLevel(),
			'recommendations',
			userId,
			location,
			recommendationStrategy,
		] as const,
	users: () => [...travelFeatureQueryKeys.topLevel(), 'users'] as const,
};

function infiniteTravelRecommendationsQueryOptions({
	location,
	pageSize,
	recommendationStrategy,
	userId,
	options,
}: {
	location: string | null;
	pageSize: number;
	recommendationStrategy: TravelRecommendationStrategy;
	userId: number | null;
	options?: Pick<UseQueryOptions, 'enabled'>;
}) {
	let urlParams = new ImmutableURLSearchParams()
		.set('recommendation_strategy', recommendationStrategy)
		.set('page_size', pageSize.toString());

	if (location != null) urlParams = urlParams.set('location', location);
	if (userId != null) urlParams = urlParams.set('user_id', userId.toString());

	return infiniteQueryOptions({
		queryKey: travelFeatureQueryKeys.recommendations(
			userId ?? -1,
			location ?? 'NULL',
			recommendationStrategy,
		),
		queryFn: async ({ pageParam, signal }) => {
			const withPageParam = urlParams.set(
				'page_number',
				pageParam.toString(),
			);
			return get<{
				can_paginate: boolean;
				recommendations: Array<TravelCompanyRecommendation>;
			}>(`/travel/recommendations?${withPageParam.toString()}`, {
				signal,
			});
		},
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			if (lastPage.can_paginate) return allPages.length + 1;
			return void 0;
		},
		select: (data) => data.pages.flatMap((page) => page.recommendations),
		enabled: location != null && userId != null,
		staleTime: 10 * 60 * 1000, // 10 minutes
		...options,
	});
}

export function useInfiniteTravelRecommendations({
	location,
	pageSize,
	recommendationStrategy,
	userId,
	options,
}: {
	location: string | null;
	pageSize: number;
	recommendationStrategy: TravelRecommendationStrategy;
	userId: number | null;
	options?: Pick<UseQueryOptions, 'enabled'>;
}) {
	return useInfiniteQuery(
		infiniteTravelRecommendationsQueryOptions({
			location,
			pageSize,
			recommendationStrategy,
			userId,
			options,
		}),
	);
}

function travelRecommendationQueryOptions({
	entityId,
	entityType,
	location,
	recommendationStrategy,
	userId,
}: {
	entityId: number | null;
	entityType: EntityType;
	location: string;
	recommendationStrategy: TravelRecommendationStrategy;
	userId: number;
}) {
	return queryOptions({
		queryKey: travelFeatureQueryKeys.recommendation(
			userId,
			location,
			recommendationStrategy,
			entityId ?? -1,
			entityType,
		),
		queryFn: async ({ signal }) =>
			post<{ id: number }>(`/travel/recommendations`, {
				signal,
				body: {
					entity_id: entityId,
					entity_type: entityType,
					location,
					recommendation_strategy: recommendationStrategy,
					user_id: userId,
				},
			}),
		staleTime: 5 * 60 * 1000, // 5 minutes
		enabled: entityId != null,
	});
}

export function useTravelRecommendation({
	entityId,
	entityType,
	location,
	recommendationStrategy,
	userId,
}: {
	entityId: number | null;
	entityType: EntityType;
	location: string;
	recommendationStrategy: TravelRecommendationStrategy;
	userId: number;
}) {
	return useQuery(
		travelRecommendationQueryOptions({
			entityId,
			entityType,
			location,
			recommendationStrategy,
			userId,
		}),
	);
}

export function useTravelRecommendationFeedback({
	entityId,
	entityType,
	location,
	recommendationStrategy,
	userId,
}: {
	entityId: number | null;
	entityType: EntityType;
	location: string;
	recommendationStrategy: TravelRecommendationStrategy;
	userId: number;
}) {
	return useMutation({
		mutationFn: async ({
			feedback,
			helpful,
			id,
		}: {
			feedback: string;
			helpful: boolean;
			id: number;
		}) => {
			if (id == null) return;
			return patch<unknown>(`/travel/recommendations/${id}`, {
				body: { feedback, helpful },
			});
		},
		onMutate: async ({ helpful }) => {
			const { queryKey } = infiniteTravelRecommendationsQueryOptions({
				location,
				pageSize: -1,
				recommendationStrategy,
				userId,
			});
			await queryClient.cancelQueries({ queryKey });

			const previousData = queryClient.getQueryData(queryKey);

			if (previousData && !helpful) {
				const newData = {
					...previousData,
					pages: previousData.pages.map((page) => ({
						...page,
						recommendations: page.recommendations.filter(
							(recommendation) => {
								return !(
									recommendation.id === entityId
									&& recommendation.type === entityType
								);
							},
						),
					})),
				};
				queryClient.setQueryData(queryKey, newData);
			}

			return { previousData };
		},
		onError: (_, __, context) => {
			if (!context) return;
			const { queryKey } = infiniteTravelRecommendationsQueryOptions({
				location,
				pageSize: -1,
				recommendationStrategy,
				userId,
			});
			queryClient.setQueryData(queryKey, context.previousData);
		},
	});
}

const travelUsersQueryOptions = queryOptions({
	queryKey: travelFeatureQueryKeys.users(),
	queryFn: async ({ signal }) =>
		get<TravelUser[]>('/travel/users', { signal }),
	staleTime: Infinity, // Users are effectively static
});

export function useTravelUsers() {
	return useQuery(travelUsersQueryOptions);
}
