// @flow

import React from 'react';
import DocumentTitle from 'react-document-title';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import mapProps from 'recompose/mapProps';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import type { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/switchMap';
import styled from 'styled-components';

import type { User } from '../authentication';
import * as colors from '../colors';
import Header from '../components/header';
import View from '../components/view';
import WithUser from '../components/with-user';
import { trackEvent } from '../utils/analytics';
import { get } from '../utils/api';
import bound from '../utils/bound';
import {
	getQueryParam,
	removeQueryParam,
	setQueryParam,
} from '../utils/querystring';

import CityPicker from './city-picker';
import Feedback from './feedback';
import Suggestion from './suggestion';
import type { SuggestionData } from './types';

// TODO: Replace hard-coded usernames
const users: { [name: string]: string } = {
	Adam: 'adam-pollack',
	Alanna: 'alanna-souza',
	Anish: 'anish-zute',
	Andy: 'jenks',
	Avoilan: 'avoilan-bingham',
	Annie: 'annie',
	Cameron: 'cameron-clarke',
	Chris: 'chris',
	Erandi: 'erandi-desilva',
	John: 'john-volquez',
	Landon: 'landon-campbell',
	Marcos: 'marcos-martinez-villalba',
	Mark: 'mark',
	Masha: 'masha',
	Matt: 'matt-zwiebel',
	Molly: 'molly',
	Nick: 'nick',
	Tim: 'tim-morrissey',
	TJ: 'tj',
};

const Content = styled.div`
	background: ${colors.backgroundGray.string()};
	display: grid;
	flex: 1;
	grid-gap: 12px;
	grid-template-areas:
		'city user navigation'
		'result result result'
		'feedback feedback feedback';
	grid-template-columns: 1fr 1fr auto;
	grid-template-rows: auto 1fr auto;
	overflow-y: scroll;
	padding: 30px 20px 20px;

	@media (max-width: 720px) {
		padding: 20px;
	}
`;

const Index = styled.div`
	align-items: center;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
	color: #999;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-size: 1.2em;
	min-width: 7ch;
`;

const CurrentIndex = styled.span`
	color: ${colors.charcoalGray.string()};
`;

const Nav = styled.nav`
	display: flex;
	flex-direction: row;
`;

const NavButton = styled.button`
	align-items: center;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
	border: 0;
	color: #888;
	display: flex;
	font-size: inherit;
	outline: 0;
	padding: 0 16px;
	transition:
		color 0.1s linear,
		box-shadow 0.1s linear,
		transform 0.1s linear;

	svg {
		fill: currentColor;
	}

	&:disabled {
		color: #ddd;
	}

	&:not(:disabled) {
		cursor: pointer;

		&:hover {
			box-shadow: 0 2px 3px 3px rgba(225, 225, 225, 0.6);
			z-index: 1;
		}

		&:hover,
		&:focus {
			color: #555;
		}

		&:not(:active):hover {
			transform: translateY(-1px);
		}

		&:active {
			transition-duration: 0s;
		}
	}
`;

const PreviousButton = styled(NavButton)`
	border-radius: 5px 0 0 5px;
`;

const NextButton = styled(NavButton)`
	border-radius: 0 5px 5px 0;
`;

const Result = styled(Suggestion)`
	grid-area: result;
`;

const Select = styled.select`
	background-color: #fff;
	border: 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
	font-size: inherit;
	height: 100%;
	padding: 11px 12px;
	width: 100%;
	-webkit-appearance: none;

	&:focus {
		box-shadow: 0 2px 3px 3px rgba(225, 225, 225, 0.6);
		outline: none;
	}
`;

const UserPicker = styled.div`
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;

		right: 12px;
		top: 50%;
		transform: translateY(-50%);

		width: 0;
		height: 0;

		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid ${colors.lightGray.string()};

		transition: 0.1s border-color;
	}

	&:hover:after {
		border-top-color: ${colors.lightGray.darken(0.3).string()};
	}
`;

type RouteProps = {
	city: ?string,
	onCityChange: (city: ?string) => void,
	onSuggestionIndexChange: (suggestionIndex: number) => void,
	onUserChange: (user: ?string) => void,
	selectedUser: ?string,
	suggestionIndex: number,
};

type Props = RouteProps & { user: ?User };

type State = {
	loading: boolean,
	suggestions: ?Array<SuggestionData>,
};

class Travel extends React.Component<Props, State> {
	state = {
		loading: this.props.city != null,
		suggestions: null,
	};

	_params$: Subject<[?string, ?string]> = new Subject();
	_subscription: ?Subscription;

	componentDidMount() {
		// This tracks: anytime a user visits the Travel page
		trackEvent('Visit New Page', 'travel-view', 'travel');

		this._subscription = this._params$
			.startWith([
				this.props.city,
				this.props.selectedUser
					|| (this.props.user != null && this.props.user.username)
					|| null,
			])
			.distinctUntilChanged(
				(
					[cityA, userA]: [?string, ?string],
					[cityB, userB]: [?string, ?string],
				) => cityA === cityB && userA === userB,
			)
			.switchMap(([city, user]: [?string, ?string]) => {
				if (city == null || user == null) {
					return Observable.of(null);
				}

				this.setState({
					loading: true,
					suggestions: null,
				});
				return Observable.fromPromise(
					get('/trips/suggestions', {
						query: { city, user },
					}),
				);
			})
			.subscribe((suggestions: ?Array<SuggestionData>) => {
				this.setState({
					loading: false,
					suggestions,
				});
			});
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		this._params$.next([
			newProps.city,
			newProps.selectedUser
				|| (newProps.user != null && newProps.user.username)
				|| null,
		]);
	}

	componentWillUnmount() {
		if (this._subscription != null) this._subscription.unsubscribe();
	}

	componentDidUpdate() {
		Tooltip.rebuild();
	}

	handleCityChange = (city: ?string): void => {
		this.props.onCityChange(city);
	};

	handleFeedbackSubmitted = (
		id: number,
		helpful: boolean,
		feedback: string,
	): void => {
		this.setState((state: State): $Shape<State> => ({
			suggestions:
				state.suggestions == null
					? null
					: state.suggestions.reduce(
							(
								suggestions: Array<SuggestionData>,
								suggestion: SuggestionData,
							): Array<SuggestionData> => {
								if (suggestion.id === id) {
									if (helpful) {
										return suggestions.concat({
											...suggestion,
											feedback,
											helpful,
										});
									} else {
										return suggestions;
									}
								} else {
									return suggestions.concat(suggestion);
								}
							},
							[],
						),
		}));
	};

	handleNext = () => {
		const upperBound = (this.state.suggestions || []).length - 1;
		this.props.onSuggestionIndexChange(
			bound(
				0,
				bound(0, this.props.suggestionIndex, upperBound) + 1,
				upperBound,
			),
		);
	};

	handlePrev = () => {
		const upperBound = (this.state.suggestions || []).length - 1;
		this.props.onSuggestionIndexChange(
			bound(
				0,
				bound(0, this.props.suggestionIndex, upperBound) - 1,
				upperBound,
			),
		);
	};

	handleUserChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
		this.props.onUserChange(event.target.value);
	};

	render() {
		const user: ?string =
			this.props.selectedUser
			|| (this.props.user != null && this.props.user.username)
			|| null;
		const suggestions = this.state.suggestions || [];
		const suggestionIndex = bound(
			0,
			this.props.suggestionIndex,
			suggestions.length - 1,
		);
		const suggestion = suggestions[suggestionIndex];

		return (
			<DocumentTitle title="Travel">
				<View>
					<Tooltip />
					<Header />
					<Content>
						<CityPicker
							city={this.props.city}
							onChange={this.handleCityChange}
						/>
						<UserPicker>
							<Select
								defaultValue={
									user != null
									&& Object.values(users).includes(user)
										? user
										: ''
								}
								onChange={this.handleUserChange}
							>
								<option disabled value="">
									Who is traveling?
								</option>
								{Object.keys(users).map((name: string) => (
									<option
										key={users[name]}
										value={users[name]}
									>
										{name}
									</option>
								))}
							</Select>
						</UserPicker>
						<Nav>
							<PreviousButton
								disabled={
									suggestionIndex <= 0 || this.state.loading
								}
								onClick={this.handlePrev}
							>
								<svg height="20" width="12">
									<path d="M 12 2 l -2 -2 l -10 10 l 10 10 l 2 -2 l -8 -8 l 8 -8" />
								</svg>
							</PreviousButton>
							{suggestionIndex >= 0
							&& this.state.suggestions != null ? (
								<Index>
									<CurrentIndex>
										{suggestionIndex + 1}
									</CurrentIndex>
									&nbsp;/&nbsp;
									{this.state.suggestions.length}
								</Index>
							) : (
								<Index />
							)}
							<NextButton
								disabled={
									suggestionIndex >= suggestions.length - 1
									|| this.state.loading
								}
								onClick={this.handleNext}
							>
								<svg height="20" width="12">
									<path d="M 0 2 l 2 -2 l 10 10 l -10 10 l -2 -2 l 8 -8 l -8 -8" />
								</svg>
							</NextButton>
						</Nav>
						{this.props.city
							&& (this.state.loading ? (
								<p>Loading...</p>
							) : (
								<Result suggestion={suggestion} />
							))}
						{!this.state.loading && suggestions.length > 0 && (
							<Feedback
								defaultFeedback={suggestion.feedback}
								defaultHelpful={suggestion.helpful}
								key={suggestion.id}
								logId={suggestion.id}
								onSubmitted={this.handleFeedbackSubmitted}
							/>
						)}
					</Content>
				</View>
			</DocumentTitle>
		);
	}
}

const TravelView = mapProps(({ navigate, location }): RouteProps => ({
	city: getQueryParam(location.search, 'city'),
	onCityChange: (city: ?string) => {
		navigate(
			{
				...location,
				search: city
					? setQueryParam(location.search, 'city', city)
					: removeQueryParam(location.search, 'city'),
			},
			{
				replace: true,
				state: {
					...location.state,
					suggestionIndex: 0,
				},
			},
		);
	},
	onSuggestionIndexChange: (suggestionIndex: number) => {
		navigate(
			{
				...location,
			},
			{
				replace: true,
				state: {
					...location.state,
					suggestionIndex,
				},
			},
		);
	},
	onUserChange: (user: ?string) => {
		navigate(
			{
				...location,
				search: user
					? setQueryParam(location.search, 'user', user)
					: removeQueryParam(location.search, 'user'),
			},
			{
				replace: true,
				state: {
					...location.state,
					suggestionIndex: 0,
				},
			},
		);
	},
	selectedUser: getQueryParam(location.search, 'user'),
	suggestionIndex:
		location.state != null && location.state.suggestionIndex != null
			? location.state.suggestionIndex
			: 0,
}))((props: RouteProps) => (
	<WithUser render={(user: ?User) => <Travel {...props} user={user} />} />
));

export default function HOCView() {
	const location = useLocation();
	const navigate = useNavigate();

	return <TravelView location={location} navigate={navigate} />;
}
