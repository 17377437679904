import {
	breakpoints,
	colors,
	effects,
	fonts,
} from '@drivecapital/design-system';
import { DollarSignCircleOutlineIcon } from '@drivecapital/design-system/icons/finance';
import { Person2Icon } from '@drivecapital/design-system/icons/human';
import { CalendarIcon } from '@drivecapital/design-system/icons/time';
import { Temporal } from '@js-temporal/polyfill';
import numeral from 'numeral';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import type { User } from '../../authentication';
import EmployeeGrowth from '../../components/employee-growth';
import type { TravelCompanyRecommendation } from '../types';

import UnstyledLatestFunnelAction from './latest-funnel-action';
import UnstyledLatestInteraction from './latest-interaction';
import UnstyledMarketMaps from './market-maps';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const Loading = styled.div`
	animation: ${shimmer} 1.5s infinite linear;
	background: ${colors.background.background};
	background-image: linear-gradient(
		to right,
		${colors.background.background} 0%,
		${colors.background.wrapper} 20%,
		${colors.background.background} 40%,
		${colors.background.background} 100%
	);
	background-repeat: no-repeat;
	background-size: 800px 100%;
	border-radius: 12px;
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
`;

const Card = styled.div`
	${effects.shadow.elevated};
	background-color: ${colors.layer.layer};
	border-radius: 12px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 10px 8px;
	width: 100%;

	@media (${breakpoints.sm}) {
		height: 250px;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	/* fonts.h4.productive.line-height */
	height: 24px;
	gap: 8px;
`;

const Logo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	grid-area: logo;
	height: 24px;
	object-fit: cover;
	width: 24px;
`;

const LogoLoading = styled(Loading)`
	border-radius: 50%;
	height: 24px;
	width: 24px;
`;

const Name = styled(Link)`
	${fonts.h4.productive};
	color: ${colors.text.interactive};
	grid-area: name;

	&:hover {
		color: ${colors.text.interactiveHover};
		cursor: pointer;
	}
`;
const NameLoading = styled(Loading)`
	flex: 1;
`;

const CompanyBody = styled.div`
	display: grid;
	gap: 0px;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	grid-tempalte-columns: 1fr;
	@media (${breakpoints.sm}) {
		grid-template-columns: 1fr 1fr;
	}
`;
const CompanyBodyLeft = styled.div`
	display: grid;
	gap: 12px;
	grid-template-rows: 140px 1fr;
	justify-content: space-between;
	min-height: 0px;
	overflow: hidden;
	padding: 8px;
	text-overflow: ellipsis;

	border-bottom: 1px solid ${colors.border.subtle};
	border-right: none;
	padding-bottom: 8px;
	@media (${breakpoints.sm}) {
		border-bottom: none;
		border-right: 1px solid ${colors.border.subtle};
		padding-bottom: 0px;
	}
`;

const CompanyBodyRight = styled.div`
	display: grid;
	grid-template:
		'latest-interaction' 1fr
		'market-maps' min-content
		'latest-funnel-action' min-content
		/ 1fr;
	gap: 12px;
	overflow: hidden;
	padding: 8px;
`;
const LatestInteraction = styled(UnstyledLatestInteraction)`
	grid-area: latest-interaction;
`;
const MarketMaps = styled(UnstyledMarketMaps)`
	grid-area: market-maps;
`;
const LatestFunnelAction = styled(UnstyledLatestFunnelAction)`
	grid-area: latest-funnel-action;
`;

const Description = styled.span`
	${fonts.paragraph.paragraph};
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: 0px;

	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 7;
	word-break: break-word;
`;

const Data = styled.div`
	align-items: center;
	display: flex;
	gap: 6px;
`;
const Founded = styled.div`
	${fonts.label.label};
	align-items: center;
	display: flex;
	gap: 4px;

	svg {
		height: 16px;
		width: 16px;
	}
`;

const Funding = styled.div`
	${fonts.label.label};
	align-items: center;
	display: flex;
	gap: 4px;

	svg {
		height: 16px;
		width: 16px;
	}
`;

const Employees = styled.div`
	${fonts.label.label};
	align-items: center;
	display: flex;
	gap: 4px;

	svg {
		height: 16px;
		width: 16px;
	}
`;

function formatCompanyFounded(founded: string): string {
	return Temporal.PlainDate.from(founded).year.toString();
}

export function CompanyRecommendationLoading() {
	return (
		<Card>
			<Header>
				<LogoLoading />
				<NameLoading />
			</Header>
			<Loading />
		</Card>
	);
}

interface Props {
	readonly authUser: User;
	readonly company: TravelCompanyRecommendation;
}

export default function CompanyRecommendation({ authUser, company }: Props) {
	return (
		<Card>
			<Header>
				<Logo src={company.logo_url} />
				<Name to={`/companies/${company.id}`}>{company.name}</Name>
			</Header>
			<CompanyBody>
				<CompanyBodyLeft>
					<Description title={company.description}>
						{company.description}
					</Description>
					<Data>
						{company.founded && (
							<Founded>
								<CalendarIcon />
								<span title="Founded Year">
									{formatCompanyFounded(company.founded)}
								</span>
							</Founded>
						)}

						{company.equity_funding && (
							<Funding>
								<DollarSignCircleOutlineIcon />
								<span title="Total Raised">
									{numeral(company.equity_funding)
										.format('$0[.]0a')
										.toUpperCase()}
								</span>
							</Funding>
						)}

						{company.employee_counts.length > 0 && (
							<Employees>
								<Person2Icon />
								<span title="Current Headcount">
									{
										company.employee_counts.toReversed()[0]
											.employees
									}
								</span>
							</Employees>
						)}

						<EmployeeGrowth
							employeeGrowthScore={company.growth_score}
							label=""
							recentHeadcounts={company.employee_counts}
						/>
					</Data>
				</CompanyBodyLeft>
				<CompanyBodyRight>
					<LatestInteraction company={company} />
					<MarketMaps marketMaps={company.market_maps} />
					<LatestFunnelAction
						authUser={authUser}
						funnelAction={company.latest_funnel_action}
					/>
				</CompanyBodyRight>
			</CompanyBody>
		</Card>
	);
}
