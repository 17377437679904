import { colors, fonts } from '@drivecapital/design-system';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UnstyledFetchPersonEmail from '../../profiles/fetch-person-email';
import { trackEvent } from '../../utils/analytics';
import reportError from '../../utils/sentry';
import type { TravelCompanyRecommendation } from '../types';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
const Title = styled.span`
	${fonts.paragraph.paragraph};
	color: ${colors.text.primary};
`;
const ContactInfo = styled.div`
	align-items: flex-start;
	display: grid;
	overflow: hidden;
	grid-template:
		'photo name-reason' auto
		'photo emails' auto
		/ 20px 1fr;
	gap: 6px;
`;
const PhotoWrapper = styled(Link)`
	grid-area: photo;

	> img {
		border-radius: 50%;
		height: 20px;
		width: 20px;
	}
`;
const NameReason = styled.div`
	${fonts.paragraph.paragraph};
	grid-area: name-reason;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
const Name = styled(Link)`
	color: ${colors.text.interactive};

	&:hover {
		color: ${colors.text.interactiveHover};
	}
`;
const Reason = styled.span`
	color: ${colors.text.secondary};
	margin-left: 4px;
`;
const FetchPersonEmail = styled(UnstyledFetchPersonEmail)`
	grid-area: emails;

	> a {
		${fonts.paragraph.underline};
	}
`;

function trackEmailClick() {
	trackEvent(
		'Copy Email Address',
		'company-recommendation-top-contact',
		'travel',
	);
}

interface Props {
	readonly person: NonNullable<TravelCompanyRecommendation['top_contact']>;
}

export default function KeyPerson({ person }: Props) {
	const contact = person.person;
	const contactName = `${contact.first_name} ${contact.last_name}`;
	const to = `/people/${contact.id}`;
	const handleEmailCopy = useCallback(async (value: string) => {
		try {
			trackEmailClick();
			await navigator.clipboard.writeText(value);
		} catch (error) {
			reportError(error);
		}
	}, []);

	return (
		<Body>
			<Title>Key Person</Title>
			<ContactInfo>
				<PhotoWrapper to={to}>
					<img src={contact.photo_url} alt={contactName} />
				</PhotoWrapper>
				<NameReason>
					<Name to={to}>{contactName}</Name>
					<Reason>{person.reason}</Reason>
				</NameReason>
				<FetchPersonEmail
					existingEmail={contact.email_address}
					onClick={trackEmailClick}
					onCopy={handleEmailCopy}
					personId={contact.id}
				/>
			</ContactInfo>
		</Body>
	);
}
