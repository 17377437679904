// @flow

import React from 'react';
import styled from 'styled-components';

import Highlight from '../components/highlight';

const StateHighlight = styled(Highlight)`
	color: #aaa;
`;

type Props = {
	children: string,
	match: Array<number>,
};

const HighlightedCity = ({ children: location, match, ...props }: Props) => {
	const comma = location.indexOf(',');

	if (comma < 0) {
		return (
			<Highlight match={match} {...props}>
				{location}
			</Highlight>
		);
	}

	// We want to leave the city name and its attached comma dark while
	// lightening the state name.
	const city = location.slice(0, comma + 1);
	const state = location.slice(comma + 1);

	const cityMatch = match.filter((i) => i < city.length);
	const stateMatch = match
		.filter((i) => !(i < city.length))
		.map((i) => i - city.length);

	return (
		// When we upgrade to React 16, this vestigial `<span>` can go away.
		<span {...props}>
			<Highlight match={cityMatch}>{city}</Highlight>
			<StateHighlight match={stateMatch}>{state}</StateHighlight>
		</span>
	);
};

export default HighlightedCity;
