/**
 * @file
 * Time icons from [Figma][1].
 *
 * [1]: https://www.figma.com/design/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1-5574&m=dev
 */
import React from 'react';

export function DollarSignCircleOutlineIcon(
	props: React.SVGProps<SVGSVGElement>,
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			fill="none"
			{...props}
		>
			<path
				d="M10.0049 20.0029C4.48204 20.0029 0.00488281 15.5258 0.00488281 10.0029C0.00488281 4.48008 4.48204 0.00292969 10.0049 0.00292969C15.5277 0.00292969 20.0049 4.48008 20.0049 10.0029C20.0049 15.5258 15.5277 20.0029 10.0049 20.0029ZM10.0049 18.0029C14.4232 18.0029 18.0049 14.4212 18.0049 10.0029C18.0049 5.58465 14.4232 2.00293 10.0049 2.00293C5.5866 2.00293 2.00488 5.58465 2.00488 10.0029C2.00488 14.4212 5.5866 18.0029 10.0049 18.0029ZM6.50488 12.0029H12.0049C12.281 12.0029 12.5049 11.7791 12.5049 11.5029C12.5049 11.2268 12.281 11.0029 12.0049 11.0029H8.0049C6.62417 11.0029 5.50488 9.8836 5.50488 8.5029C5.50488 7.12222 6.62417 6.00293 8.0049 6.00293H9.0049V4.00293H11.0049V6.00293H13.5049V8.0029H8.0049C7.72874 8.0029 7.50488 8.2268 7.50488 8.5029C7.50488 8.7791 7.72874 9.0029 8.0049 9.0029H12.0049C13.3856 9.0029 14.5049 10.1222 14.5049 11.5029C14.5049 12.8836 13.3856 14.0029 12.0049 14.0029H11.0049V16.0029H9.0049V14.0029H6.50488V12.0029Z"
				fill="currentColor"
			/>
		</svg>
	);
}
