import React from 'react';
import type { ReactNode, VoidFunctionComponent } from 'react';
import styled from 'styled-components';

const DefaultNormalText = styled.span``;

const DefaultMatchText = styled.span`
	color: #000;
	font-weight: bold;
`;

type Match = Array<number>;

type Props = {
	readonly children: string;
	readonly NormalText?: React.ComponentType<{ children: ReactNode }>;
	readonly MatchText?: React.ComponentType<{ children: ReactNode }>;
	readonly match: Match;
};

const Highlight: VoidFunctionComponent<Props> = ({
	children: string,
	match,
	NormalText = DefaultNormalText,
	MatchText = DefaultMatchText,
	...props
}: Props) => {
	if (match.length < 1) {
		return <NormalText {...props}>{string}</NormalText>;
	}

	const children: Array<ReactNode> = [];

	let stringIndex = 0;
	let matchIndex = 0;

	do {
		const matched = stringIndex === match[matchIndex];
		const startIndex = stringIndex;

		if (matched) {
			// Advance `matchIndex` and `stringIndex` to find the end of the
			// current match.
			do {
				matchIndex++;
				stringIndex++;
			} while (
				stringIndex < string.length
				&& stringIndex === match[matchIndex]
			);

			const substring = string.slice(startIndex, stringIndex);
			children.push(
				// To ensure a unique key, we have to include all three values.
				<MatchText key={`${startIndex}_${substring}_${stringIndex}`}>
					{substring}
				</MatchText>,
			);
		} else {
			// Advance `stringIndex` to find the beginning of the next match.
			do {
				stringIndex++;
			} while (
				stringIndex < string.length
				&& stringIndex !== match[matchIndex]
			);

			const substring = string.slice(startIndex, stringIndex);
			children.push(
				// To ensure a unique key, we have to include all three values.
				<NormalText key={`${startIndex}_${substring}_${stringIndex}`}>
					{substring}
				</NormalText>,
			);
		}
	} while (stringIndex < string.length);

	return <span {...props}>{children}</span>;
};

export default Highlight;
