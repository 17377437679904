import { colors } from '@drivecapital/design-system';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Tooltip from '../../components/unstyled-tooltip';
import { trackEvent } from '../../utils/analytics';
import ProfileTooltipCard, {
	Logo,
	SubTitle,
	Title,
	TitleAndLogoContainer,
} from '../summaries/profile-tooltip-card';

import type { EventPersonData } from './';

const ParticipantName = styled(Link)`
	&:hover {
		cursor: pointer;
		color: ${colors.text.interactive};
		text-decoration: underline;
	}
`;

export const buildFromList = (
	participants: Array<EventPersonData>,
	eventId: string,
) =>
	participants.map((participant, index) => (
		<span key={participant.id}>
			<EventParticipant {...participant} eventId={eventId} />
			{index !== participants.length - 1 && ', '}
		</span>
	));

const buildTooltipId = (id: number, eventId: string) =>
	`timelineEventPerson-${id}-${eventId}`;

type Props = EventPersonData & {
	readonly eventId: string;
	readonly tooltipPlace?: 'top' | 'bottom';
};

export default function EventParticipant({
	current_job,
	eventId,
	first_name,
	id,
	last_name,
	photo_url,
	tooltipPlace = 'bottom',
}: Props) {
	return (
		<>
			<ParticipantName
				to={`/people/${id}`}
				data-tip
				data-for={buildTooltipId(id, eventId)}
				onMouseDown={() =>
					trackEvent(
						'Open Profile',
						'summary-timeline',
						'companies',
						{ target_profile_type: 'people' },
					)
				}
			>
				{first_name}&nbsp;{last_name}
			</ParticipantName>
			<Tooltip
				effect="float"
				id={buildTooltipId(id, eventId)}
				place={tooltipPlace}
			>
				<ProfileTooltipCard
					imageUrl={photo_url}
					title={`${first_name} ${last_name}`}
				>
					{current_job && (
						<TitleAndLogoContainer>
							<Logo src={current_job.company_logo_url} />
							<span>
								<Title>{current_job.company_name}</Title>{' '}
								<SubTitle>{current_job.title}</SubTitle>
							</span>
						</TitleAndLogoContainer>
					)}
				</ProfileTooltipCard>
			</Tooltip>
		</>
	);
}
