// @flow

import omit from 'lodash.omit';
import querystring from 'querystring';

function parse(search: string) {
	// `querystring.parse` does not expect a leading "?", so trim it if present.
	return querystring.parse(search.replace(/^\?/u, ''));
}

/** @deprecated Use ImmutableURLSearchParams instead. */
// `querystring.stringify` takes a plain object literal.
// eslint-disable-next-line flowtype/no-weak-types
function stringify(query: Object): string {
	const search = querystring.stringify(query);
	if (search === '') return '';
	else return `?${search}`;
}

/** @deprecated Use ImmutableURLSearchParams instead. */
export function getQueryParam(search: string, name: string) {
	return parse(search)[name];
}

/** @deprecated Use ImmutableURLSearchParams instead. */
export function removeQueryParam(search: string, name: string): string {
	return stringify(omit(parse(search), name));
}

/** @deprecated Use ImmutableURLSearchParams instead. */
export function setQueryParam(
	search: string,
	name: string,
	// `querystring.stringify` allows any type of value.
	// eslint-disable-next-line flowtype/no-weak-types
	value: any,
): string {
	return stringify({
		...parse(search),
		[name]: value,
	});
}
