import { colors, fonts } from '@drivecapital/design-system';
import { Page2Icon } from '@drivecapital/design-system/icons/document';
import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import { useUser } from '../authentication';
import { isDriveTenant } from '../components/Authorized';
import { ResponseError } from '../utils/api';

import { useFetchPersonEmails as useApiFetchPersonEmails } from './api';

const Email = styled.div`
	align-items: center;
	display: flex;
	overflow: hidden;

	> a {
		${fonts.label.underline}
		color: ${colors.text.interactive};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:hover {
			color: ${colors.text.interactiveHover};
		}
	}
`;
const CopyButton = styled.button`
	background: none;
	border: none;
	color: ${colors.text.secondary};
	cursor: pointer;
	line-height: 0;
	transition: color 0.1s ease-in-out;

	&:hover {
		color: ${colors.icon.secondaryHover};
	}

	> svg {
		height: 16px;
		width: 16px;
	}
`;

const Error = styled.div`
	${fonts.label.label};
	color: ${colors.text.disabled};
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;
const SkeletonLoader = styled.div`
	animation: ${shimmer} 1.5s infinite linear;
	background: ${colors.background.background};
	background-image: linear-gradient(
		to right,
		${colors.background.background} 0%,
		${colors.background.wrapper} 20%,
		${colors.background.background} 40%,
		${colors.background.background} 100%
	);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	border-radius: 4px;
	display: inline-block;
	min-height: 16px;
	position: relative;
	width: 100%;
`;

export function useFetchPersonEmails({
	enabled,
	existingEmail,
	personId,
}: {
	enabled: boolean;
	existingEmail: string | null;
	personId: number;
}) {
	const {
		data: emails,
		isLoading,
		error,
	} = useApiFetchPersonEmails(personId, {
		enabled: enabled && !existingEmail,
	});

	return { emails, isLoading, error };
}

interface PersonEmailProps {
	readonly className?: string;
	readonly email: string | null;
	readonly isLoading: boolean;
	readonly error: unknown;
	readonly onClick?: () => void;
	readonly onCopy?: (value: string) => void;
}

export function PersonEmail({
	className,
	email,
	error,
	isLoading,
	onClick,
	onCopy,
}: PersonEmailProps) {
	const handleCopy = useCallback(() => {
		if (!email) return;

		if (onCopy) {
			onCopy(email);
		} else {
			void navigator.clipboard.writeText(email);
		}
	}, [email, onCopy]);

	if (isLoading) {
		return <SkeletonLoader className={className} />;
	}

	if (error && error instanceof ResponseError && error.rfc9457) {
		return <Error className={className}>{error.rfc9457.detail}</Error>;
	} else if (error) {
		return (
			<Error className={className}>Unknown error fetching emails</Error>
		);
	}

	if (!email) {
		return null;
	}

	return (
		<Email className={className}>
			<a href={`mailto:${email}`} onClick={onClick}>
				{email}
			</a>
			<CopyButton onClick={handleCopy}>
				<Page2Icon />
			</CopyButton>
		</Email>
	);
}

interface Props {
	readonly className?: string;
	readonly existingEmail: string | null;
	readonly onClick?: () => void;
	readonly onCopy?: (value: string) => void;
	readonly personId: number;
}

export default function FetchPersonEmail({
	className,
	existingEmail,
	onClick,
	onCopy,
	personId,
}: Props) {
	const user = useUser();
	const { emails, isLoading, error } = useFetchPersonEmails({
		enabled: isDriveTenant(user),
		existingEmail,
		personId,
	});

	const shownEmail = existingEmail
		? existingEmail
		: emails
			? emails.work_email || emails.personal_email
			: null;

	return (
		<PersonEmail
			className={className}
			email={shownEmail}
			error={error}
			isLoading={isLoading}
			onClick={onClick}
			onCopy={onCopy}
		/>
	);
}
