import styled from 'styled-components';

import { lightGray, white } from '../colors';

/**
 * @example
 * <ButtonGroup>
 *    <GroupButton>
 *        <input
 *            defaultChecked
 *            name="dessert"
 *            type="radio"
 *            value="pie"
 *        />
 *        <span>Pie</span>
 *    </GroupButton>
 *    <GroupButton>
 *        <input
 *            name="dessert"
 *            type="radio"
 *            value="cheesecake"
 *        />
 *        <span>Cheesecake</span>
 *    </GroupButton>
 * </ButtonGroup>
 */
export const ButtonGroup = styled.div`
	display: flex;

	label:first-child span {
		border-radius: 5px 0 0 5px;
	}

	label:last-child span {
		border-radius: 0 5px 5px 0;
	}

	label:not(:last-child) span {
		margin-right: -1px;
	}

	label:first-child {
		border-radius: 5px 0 0 5px;
	}

	label:last-child {
		border-radius: 0 5px 5px 0;
	}
`;

export const GroupButton = styled.label`
	input {
		position: absolute;
		top: -10000px;
		left: -10000px;
	}

	span {
		svg {
			height: 24px;
			width: 24px;
			margin-right: 4px;
			transition: 0.1s fill;
		}

		align-items: center;
		border: 1px solid ${lightGray.string()};
		display: flex;
		font-weight: 600;
		justify-content: center;
		letter-spacing: 1px;
		padding: 7px 18px 7px 8px;
		transition:
			0.1s background-color,
			0.1s border-color,
			0.1s color;
	}

	span:hover {
		background: ${white.darken(0.05).string()};
	}

	input:checked + span {
		position: relative;
		color: ${white.string()};
	}

	@media (max-width: 736px) {
		flex-grow: 1;
	}
`;
