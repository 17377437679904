import { colors, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { PlusIcon } from '@drivecapital/design-system/icons/system';
import React from 'react';
import styled from 'styled-components';

import type { TravelCompanyRecommendation } from '../types';

import LatestInteractionHeader from './latest-interaction-header';

const Body = styled.div`
	${fonts.paragraph.paragraph};
	align-items: flex-start;
	color: ${colors.text.primary};
	display: flex;
	gap: 2px;
`;
const IconWrapper = styled.div`
	align-items: center;
	display: flex;
	padding: 2px 0;

	svg {
		height: 12px;
		width: 12px;
	}
`;

interface Props {
	readonly className?: string;
	readonly companyName: string;
}

export function AddedToHerbieHeader({
	date,
}: {
	readonly date: NonNullable<
		TravelCompanyRecommendation['user_added_via_extension']
	>;
}) {
	return (
		<LatestInteractionHeader
			chip={
				<Chip
					background={colors.data.dust.layer}
					color={colors.data.dust.textSecondary}
					icon={null}
					label="Herbie"
				/>
			}
			date={date}
		/>
	);
}

export function AddedToHerbieBody({ className, companyName }: Props) {
	return (
		<Body className={className}>
			<IconWrapper>
				<PlusIcon />
			</IconWrapper>
			<span>
				You added {companyName} to Herbie via the Chrome extension
			</span>
		</Body>
	);
}
