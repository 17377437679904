import { colors, effects, fonts } from '@drivecapital/design-system';
import { SpeechBubbleIcon } from '@drivecapital/design-system/icons/human';
import { Temporal } from '@js-temporal/polyfill';
import React from 'react';
import styled from 'styled-components';

import type { User } from '../../authentication';
import Tooltip, { UnstyledTooltipContent } from '../../components/tooltip';
import {
	AssignedChip,
	PassedChip,
	SnoozedChip,
	SurfacedChip,
} from '../../profiles/sections/high-beam/chips';
import assertExhaustive from '../../utils/assert-exhaustive';
import type { TravelCompanyRecommendation } from '../types';

type Action = NonNullable<
	TravelCompanyRecommendation['latest_funnel_action']
>['action'];
function ActionChip({ action }: { readonly action: Action }) {
	switch (action) {
		case 'ASSIGN':
			return <AssignedChip />;
		case 'PASS':
			return <PassedChip />;
		case 'SNOOZE':
			return <SnoozedChip />;
		case 'SURFACE':
			return <SurfacedChip />;
		default:
			return assertExhaustive(action);
	}
}

function actionWord(action: Action) {
	switch (action) {
		case 'ASSIGN':
			return 'to';
		case 'PASS':
			return 'by';
		case 'SNOOZE':
			return 'by';
		case 'SURFACE':
			return 'by';
		default:
			return assertExhaustive(action);
	}
}

const Body = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
`;
const UserText = styled.span`
	${fonts.paragraph.strong};
	color: ${colors.text.primary};
`;
const Timestamp = styled.span`
	${fonts.paragraph.paragraph};
	color: ${colors.text.secondary};
`;
const TooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.hover}
	align-items: center;
	background-color: ${colors.card.card};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	gap: 4px;
	padding: 8px 12px;

	svg {
		height: 16px;
		width: 16px;
	}
`;

interface Props {
	readonly authUser: User;
	readonly className?: string;
	readonly funnelAction: TravelCompanyRecommendation['latest_funnel_action'];
}

export default function LatestFunnelAction({
	authUser,
	className,
	funnelAction,
}: Props) {
	if (funnelAction == null) return <div className={className} />;

	const actionUser =
		funnelAction.user.id === authUser.id
			? 'You'
			: funnelAction.user.first_name;

	const body = (
		<Body>
			<ActionChip action={funnelAction.action} />
			<UserText>
				{actionWord(funnelAction.action)} {actionUser}
			</UserText>
			<Timestamp>
				on{' '}
				{Temporal.PlainDate.from(
					funnelAction.timestamp,
				).toLocaleString()}
			</Timestamp>
		</Body>
	);

	if (funnelAction.action === 'PASS' || funnelAction.action === 'SNOOZE') {
		const reasonText =
			typeof funnelAction.reason === 'string'
				? funnelAction.reason
				: funnelAction.reason.explanation;
		return (
			<Tooltip
				content={
					<TooltipContent>
						<SpeechBubbleIcon />
						{reasonText}
					</TooltipContent>
				}
			>
				{body}
			</Tooltip>
		);
	}

	return body;
}
