// @flow

import type { Node as ReactNode } from 'react';
import { connect } from 'react-redux';

import type { User } from '../authentication';
import type { State as StoreState } from '../reducers';
import { selectUser } from '../selectors';

type OwnProps = {| render: (user: ?User) => ReactNode |};
type StateProps = {| user: ?User |};
type Props = {|
	...OwnProps,
	...StateProps,
|};

function WithUser({ render, user }: Props): ReactNode {
	return render(user);
}

export default connect<_, OwnProps, _, _, _, _>(
	(state: StoreState): StateProps => ({
		user: selectUser(state),
	}),
	() => ({}),
)(WithUser);
