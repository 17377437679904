import { colors, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { PhoneIcon } from '@drivecapital/design-system/icons/devices';
import { Person2Icon } from '@drivecapital/design-system/icons/human';
import {
	CiscoWebexIcon,
	GoogleMeetIcon,
	MicrosoftTeamsIcon,
	ZoomIcon,
} from '@drivecapital/design-system/icons/social';
import { CalendarIcon } from '@drivecapital/design-system/icons/time';
import { MapPinIcon } from '@drivecapital/design-system/icons/travel';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import Header from './event-header';
import { buildFromList } from './event-participant';
import { formatDate } from './parse-date';

import type { CalendarEvent } from './';

const Summary = styled.div`
	${fonts.label.strong}
	color: ${colors.text.primary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;
const ToggleChipsContainer = styled.div`
	display: grid;
`;
const ParticipantToggleChip = styled(Chip)<{ $hide: boolean }>`
	grid-column: 1;
	grid-row: 1;
	justify-content: center;

	${(props) => props.$hide && 'opacity: 0; pointer-events: none;'}
	&:hover {
		cursor: pointer;
	}
`;
const MetadataRow = styled.div<{ $expanded: boolean }>`
	${fonts.label.label}
	align-items: flex-start;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-rows: auto;
	grid-template-columns: 12px 1fr auto;
	text-overflow: ellipsis;
	width: 100%;
	${(props) => !props.$expanded && 'overflow: hidden;'}

	div {
		${(props) => !props.$expanded && 'overflow: hidden;'}
		text-overflow: ellipsis;
	}

	span {
		${(props) => !props.$expanded && 'overflow: hidden;'}
		text-overflow: ellipsis;
	}

	svg {
		color: ${colors.icon.primary};
		flex: 0 0 12px;
		height: 12px;
		margin-top: 2px;
		width: 12px;
	}
`;
const SVGRow = styled.div`
	${fonts.label.label}
	align-items: flex-start;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-rows: auto;
	grid-template-columns: 12px 1fr auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	div {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		color: ${colors.icon.primary};
		flex: 0 0 12px;
		height: 12px;
		margin-top: 2px;
		width: 12px;
	}
`;

const MIN_PARTICIPANT_COUNT = 5;

// This regular expression is unmaintainable and was generated with ChatGPT default v3.5
// It's accompanied with unit tests which cover the cases we expect
// If this needs updated, write new tests and re-prompt until they pass
// Something like: Write a regular expression in Javascript to parse from a string all valid phone numbers with united states, canadian, united arab emerates, and eurpoean formats
// If this becomes too unbearable, checkout google's libphonenumber library
const PHONE_NUMBER_REGEX =
	/((\+971\s?(-|\d){1,2}\s?)?\d{1,2}[-\s]?\d{3}[-\s]?\d{4})|((\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4})|((\+44\s?|0)\d{4}[-.\s]?\d{6})/gu;
const URL_REGEX = /(https?|ftp):\/\/[^\s/$.?#"].[^\s<"]*/gu;

const ensureURL = (possibleUrl: string): string => {
	try {
		new URL(possibleUrl.trim());
		return possibleUrl.trim();
	} catch {
		return '';
	}
};

export const extractLocations = (
	description: string,
	location: string,
): {
	phoneNumberMatches: Array<string>;
	physicalAddress: string | null;
	urlMatches: Array<string>;
} => {
	const locationUrlMatches = [
		...new Set(
			(location.match(URL_REGEX) || []).map(ensureURL).filter(Boolean),
		),
	];
	const descriptionUrlMatches = [
		...new Set(
			(description.match(URL_REGEX) || []).map(ensureURL).filter(Boolean),
		),
	];
	const urlMatches = [
		...new Set(locationUrlMatches.concat(descriptionUrlMatches)),
	];

	const phoneNumberMatches = [
		...new Set(
			(location.match(PHONE_NUMBER_REGEX) || []).map((m) => m.trim()),
		),
	].filter(
		(phoneNumber) => !urlMatches.some((url) => url.includes(phoneNumber)),
	);

	const physicalAddress =
		phoneNumberMatches.length + locationUrlMatches.length === 0
		&& location !== ''
			? location
			: null;

	return { phoneNumberMatches, physicalAddress, urlMatches };
};

export const getEventDetails = (
	conference_data: CalendarEvent['conference_data'],
	description: string,
	location: string,
): {
	phoneNumbers: Array<string>;
	physicalAddress: string | null;
	virtualMeetingLinks: {
		cisco_webex: Array<string>;
		google: Array<string>;
		microsoft_teams: Array<string>;
		zoom: Array<string>;
	};
} => {
	let urlMatches: Array<string> = [];
	let phoneNumberMatches: Array<string> = [];
	let physicalAddress: string | null = null;

	if (conference_data) {
		urlMatches = [
			...new Set(
				conference_data
					.filter((datum) => datum.type === 'video')
					.map((datum) => ensureURL(datum.value)),
			),
		];
		phoneNumberMatches = [
			...new Set(
				conference_data
					.filter((datum) => datum.type === 'phone')
					.map((datum) => datum.value),
			),
		];
		physicalAddress =
			![...urlMatches, ...phoneNumberMatches].includes(location)
			&& location !== ''
				? location
				: null;
	} else {
		const locationMatches = extractLocations(description, location);
		urlMatches = locationMatches.urlMatches;
		phoneNumberMatches = locationMatches.phoneNumberMatches;
		physicalAddress = locationMatches.physicalAddress;
	}

	return {
		phoneNumbers: phoneNumberMatches,
		physicalAddress,
		virtualMeetingLinks: {
			cisco_webex: urlMatches.filter((url) =>
				new URL(url).hostname.includes('webex.com'),
			),
			google: urlMatches.filter((url) =>
				[
					'hangouts.google.com',
					'plus.google.com',
					'meet.google.com',
				].includes(new URL(url).hostname),
			),
			microsoft_teams: urlMatches.filter((url) => {
				const hostName = new URL(url).hostname;

				// https://learn.microsoft.com/en-us/microsoft-365/enterprise/urls-and-ip-address-ranges?view=o365-worldwide#skype-for-business-online-and-microsoft-teams
				return (
					hostName.includes('lync.com')
					|| hostName.includes('teams.microsoft.com')
				);
			}),
			zoom: urlMatches.filter((url) =>
				new URL(url).hostname.includes('.zoom.'),
			),
		},
	};
};

export default function CalendarEventComponent({
	conference_data,
	date,
	description,
	location,
	participant_data,
	summary,
}: CalendarEvent) {
	const [participantsExpanded, setParticipantsExpanded] =
		useState<boolean>(false);
	const visibleParticipants = participant_data.participants.slice(
		0,
		participantsExpanded ? void 0 : MIN_PARTICIPANT_COUNT,
	);
	const participantNames = useMemo(
		() => buildFromList(visibleParticipants, `calendar-${date}`),
		[date, visibleParticipants],
	);
	const renderToggle =
		participant_data.participants.length > MIN_PARTICIPANT_COUNT;
	const handleToggleClick = useCallback(() => {
		setParticipantsExpanded((current) => !current);
	}, []);
	const eventDetails = useMemo(
		() => getEventDetails(conference_data, description, location),
		[conference_data, description, location],
	);

	return (
		<>
			<Header>
				<Chip
					background={colors.data.dust.layer}
					color={colors.data.dust.textSecondary}
					icon={<CalendarIcon />}
					label="Calendar"
				/>
				{formatDate(date)}
			</Header>
			<Summary title={summary}>{summary}</Summary>
			<MetadataRow $expanded={renderToggle ? participantsExpanded : true}>
				<Person2Icon />
				<div>
					{participantNames}
					{participantsExpanded && !participant_data.all_included && (
						<span>, and others...</span>
					)}
					{!participantsExpanded && renderToggle && <span>...</span>}
				</div>
				{renderToggle && (
					<ToggleChipsContainer>
						<ParticipantToggleChip
							$hide={!participantsExpanded}
							background={colors.data.dust.layer}
							color={colors.data.dust.textSecondary}
							onClick={handleToggleClick}
							menu
							menuDirection="up"
							size="small"
						/>
						<ParticipantToggleChip
							$hide={participantsExpanded}
							background={colors.data.dust.layer}
							color={colors.data.dust.textSecondary}
							onClick={handleToggleClick}
							label={`+${
								participant_data.participants.length
								- MIN_PARTICIPANT_COUNT
							}`}
							size="small"
						/>
					</ToggleChipsContainer>
				)}
			</MetadataRow>
			{eventDetails.physicalAddress && (
				<SVGRow>
					<MapPinIcon />
					<span>{eventDetails.physicalAddress}</span>
				</SVGRow>
			)}
			{eventDetails.phoneNumbers.length > 0 && (
				<SVGRow>
					<PhoneIcon />
					<span>{eventDetails.phoneNumbers.join(', ')}</span>
				</SVGRow>
			)}
			{eventDetails.virtualMeetingLinks.zoom.length > 0 && (
				<SVGRow>
					<ZoomIcon />
					<span>Zoom Meeting</span>
				</SVGRow>
			)}
			{eventDetails.virtualMeetingLinks.google.length > 0 && (
				<SVGRow>
					<GoogleMeetIcon />
					<span>Google Hangout Meeting</span>
				</SVGRow>
			)}
			{eventDetails.virtualMeetingLinks.microsoft_teams.length > 0 && (
				<SVGRow>
					<MicrosoftTeamsIcon />
					<span>Teams Meeting</span>
				</SVGRow>
			)}
			{eventDetails.virtualMeetingLinks.cisco_webex.length > 0 && (
				<SVGRow>
					<CiscoWebexIcon />
					<span>Webex Meeting</span>
				</SVGRow>
			)}
		</>
	);
}
