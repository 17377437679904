import React, { type ComponentProps, type ReactNode } from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
	flex-shrink: 0;
	height: 24px;
	width: 24px;

	@media (max-width: 420px) {
		margin-left: -6px;
	}
`;

const ItemText = styled.div`
	padding-left: 24px;
	text-indent: -24px;
`;

const List = styled.ul`
	clear: left;
	list-style-type: none;
	margin-bottom: 10px;
	padding-left: 0;

	// Landscape iPhone 5
	@media (min-width: 568px) {
		clear: none;
	}
`;

const ListItem = styled.li`
	display: flex;
	line-height: 1.5em;
	padding: 0.15em 0;
	font-weight: 300;
`;

const Title = styled.span`
	font-weight: 400;
	white-space: pre;
`;

interface InfoProps extends ComponentProps<typeof ListItem> {
	readonly children?: ReactNode;
	readonly icon?: ReactNode;
	readonly title: string;
	readonly url?: string;
}

export const Info = ({ children, icon, title, ...props }: InfoProps) => (
	<ListItem {...props}>
		{icon != null && <IconWrapper>{icon}</IconWrapper>}
		<ItemText>
			<Title>{title}: </Title>
			{children}
		</ItemText>
	</ListItem>
);

interface InfoListProps extends ComponentProps<typeof List> {
	readonly children: ReactNode;
}

export const InfoList = ({ children, ...props }: InfoListProps) => (
	<List {...props}>{children}</List>
);
