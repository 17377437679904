import { colors, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { QuillPenIcon } from '@drivecapital/design-system/icons/document';
import UnstyledEditor from '@drivecapital/text-editor';
import React from 'react';
import styled from 'styled-components';

import reportError from '../../utils/sentry';
import type { TravelCompanyRecommendation } from '../types';

import LatestInteractionHeader from './latest-interaction-header';

const Body = styled.div`
	${fonts.paragraph.paragraph};
	--box-shadow-color: ${colors.layer.layer};

	border-left: 2px solid ${colors.border.subtle};
	color: ${colors.text.primary};
	width: 100%;
`;
const EditorContainer = styled.div`
	overflow: hidden;
	max-height: 90px;

	position: relative;
	&:before {
		box-shadow:
			inset 0 0 0 0,
			inset 0 -22px 10px -12px var(--box-shadow-color);
		content: '';
		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.HerbieTextEditor {
		&__content_editable {
			color: ${colors.text.secondary};
			padding: 0px 4px;
		}
	}
`;
const TextEditor = styled(UnstyledEditor)``;

interface Props {
	readonly className?: string;
	readonly note: NonNullable<TravelCompanyRecommendation['latest_note']>;
}

export function LatestNoteHeader({ note }: Props) {
	return (
		<LatestInteractionHeader
			chip={
				<Chip
					background={colors.data.dust.layer}
					color={colors.data.dust.textSecondary}
					icon={<QuillPenIcon />}
					label="Note"
				/>
			}
			date={note.date}
		/>
	);
}

export function LatestNoteBody({ className, note }: Props) {
	return (
		<Body>
			<EditorContainer className={className}>
				<TextEditor
					editable={false}
					initialContent={note.raw_comment || note.comment}
					onError={reportError}
					namespace="travel-note"
				/>
			</EditorContainer>
		</Body>
	);
}
