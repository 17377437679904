// @flow

import React, { type ElementConfig as ReactElementConfig } from 'react';
import { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import styled from 'styled-components';

import * as colors from '../colors';
import Button from '../components/button';
import {
	ButtonGroup,
	GroupButton as UnstyledGroupButton,
} from '../components/button-group';
import Section from '../profiles/section';
import { put } from '../utils/api';
import type { ResponseError } from '../utils/api';
import reportError from '../utils/sentry';

const FeedbackRow = styled.div`
	display: flex;
	margin: 15px 0 0;
`;

const FeedbackSection = styled<ReactElementConfig<typeof Section>, void>(
	Section,
)`
	grid-area: feedback;
`;

const GrowColumn = styled.div`
	flex-grow: 1;
	margin: 0px 0px 0px 15px;

	input,
	select {
		width: 100%;
	}
`;

const GroupButton = styled(UnstyledGroupButton)`
	span {
		svg {
			height: 12px;
			width: 14px;
		}

		padding: 11px 20px;
	}

	@media (max-width: 1024px) {
		span {
			padding: 11px 15px;
		}
	}

	input:checked + span {
		svg {
			fill: ${colors.white.string()};
		}
	}

	@media (max-width: 736px) {
		flex-grow: 1;
	}
`;

const GreenOption = styled(GroupButton)`
	span {
		color: ${colors.driveGreen.string()};
	}

	svg {
		fill: ${colors.driveGreen.string()};
	}

	input:checked + span {
		position: relative;
		background: ${colors.driveGreen.string()};
		border-color: ${colors.driveGreen.darken(0.15).string()};
	}
`;

const RedOption = styled(GroupButton)`
	span {
		color: ${colors.red.string()};
	}

	svg {
		fill: ${colors.red.string()};
	}

	input:checked + span {
		background: ${colors.red.string()};
		border-color: ${colors.red.darken(0.15).string()};
	}
`;

const SubmitButton = styled(Button)`
	margin: 0px 0px 0px 15px;
	border-radius: 5px;
	letter-spacing: 1px;

	@media (max-width: 1024px) {
		padding: 12px 15px;
	}
`;

const TextInput = styled.input`
	background: ${colors.white.string()};
	border: 1px solid ${colors.lightGray.string()};
	border-radius: 5px;
	padding: 11px 12px;
	font-size: inherit;
`;

const statuses = {
	pending: 'pending',
	submitting: 'submitting',
	submitted: 'submitted',
	failed: 'failed',
};

type Status = $Keys<typeof statuses>;

type Props = {
	defaultFeedback: string,
	defaultHelpful: ?boolean,
	logId: number,
	onSubmitted: (id: number, helpful: boolean, feedback: string) => void,
};

type State = {
	feedback: string,
	helpful: ?boolean,
	status: Status,
};

class Feedback extends React.Component<Props, State> {
	_subscription: ?Subscription;

	state = {
		feedback: this.props.defaultFeedback,
		helpful: this.props.defaultHelpful,
		status: statuses.pending,
	};

	handleFeedbackChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({
			feedback: event.target.value,
		});
	};

	handleFeedbackSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setState({ status: statuses.submitting });

		const { feedback, helpful } = this.state;
		if (feedback === '' || helpful == null) return;

		const body = { feedback, helpful };

		this._subscription = Observable.fromPromise(
			put(`/trips/suggestions/${this.props.logId}/`, { body }),
		).subscribe(
			() => {
				this.setState({ status: statuses.submitted });
				this.props.onSubmitted(this.props.logId, helpful, feedback);
			},
			(error: ResponseError) => {
				reportError(error);
			},
		);
	};

	handleNegativeFeedback = () => {
		this.setState({
			helpful: false,
		});
	};

	handlePositiveFeedback = () => {
		this.setState({
			helpful: true,
		});
	};

	render() {
		const { helpful, feedback, status } = this.state;

		return (
			<FeedbackSection title="Feedback">
				<form onSubmit={this.handleFeedbackSubmit}>
					<FeedbackRow>
						<ButtonGroup>
							<GreenOption>
								<input
									name="action"
									onChange={this.handlePositiveFeedback}
									checked={helpful === true}
									type="radio"
								/>
								<span>This suggestion is great!</span>
							</GreenOption>
							<RedOption>
								<input
									name="action"
									onChange={this.handleNegativeFeedback}
									checked={helpful === false}
									type="radio"
								/>
								<span>This suggestion is no good.</span>
							</RedOption>
						</ButtonGroup>
						{helpful !== null && (
							<GrowColumn>
								<div>
									<TextInput
										onChange={this.handleFeedbackChange}
										placeholder="Why?"
										type="text"
										value={feedback}
									/>
								</div>
							</GrowColumn>
						)}
						<div>
							<SubmitButton
								disabled={
									helpful == null
									|| feedback === ''
									|| status !== statuses.pending
								}
								primary
								type="submit"
							>
								{
									{
										[statuses.pending]: 'Save',
										[statuses.submitting]: 'Saving...',
										[statuses.submitted]: 'Thanks!',
										[statuses.failed]: 'Something broke',
									}[status]
								}
							</SubmitButton>
						</div>
					</FeedbackRow>
				</form>
			</FeedbackSection>
		);
	}
}

export default Feedback;
