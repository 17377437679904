import { ActionTypes } from '../constants';
import type { NoteData, ProfileType } from '../notes';

export const addNote = (
	profileId: number,
	notes: Array<Partial<NoteData>>,
	profileType: ProfileType,
) => ({
	type: ActionTypes.NOTES_ADD_SUCCESS,
	payload: {
		id: profileId,
		notes,
		type: profileType,
	},
});

export const deleteNote = (noteId: number) => ({
	type: ActionTypes.NOTES_DELETE_SUCCESS,
	payload: {
		id: noteId,
	},
});

export const editNote = (note: NoteData) => ({
	type: ActionTypes.NOTES_EDIT,
	payload: note,
});

export const loadNotes = (
	profileId: number,
	notes: ReadonlyArray<NoteData>,
	profileType: ProfileType,
) => ({
	type: ActionTypes.NOTES_LOAD_SUCCESS,
	payload: {
		id: profileId,
		notes,
		type: profileType,
	},
});
