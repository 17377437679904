import React from 'react';
import styled from 'styled-components';

import type { TravelCompanyRecommendation } from '../types';

import { AddedToHerbieBody, AddedToHerbieHeader } from './added-to-herbie';
import KeyPerson from './key-person';
import { LatestEmailBody, LatestEmailHeader } from './latest-email';
import { LatestEventBody, LatestEventHeader } from './latest-event';
import { LatestNoteBody, LatestNoteHeader } from './latest-note';

const Body = styled.div`
	min-width: 0;
`;

interface Props {
	readonly className?: string;
	readonly company: TravelCompanyRecommendation;
}

export default function LatestInteraction({ className, company }: Props) {
	let interaction: React.ReactNode = null;
	let header: React.ReactNode = null;

	if (company.latest_event) {
		header = <LatestEventHeader event={company.latest_event} />;
		interaction = <LatestEventBody event={company.latest_event} />;
	} else if (
		company.latest_emails.received.length > 0
		|| company.latest_emails.sent.length > 0
	) {
		header = <LatestEmailHeader emails={company.latest_emails} />;
		interaction = <LatestEmailBody emails={company.latest_emails} />;
	} else if (company.user_added_via_extension) {
		header = (
			<AddedToHerbieHeader date={company.user_added_via_extension} />
		);
		interaction = <AddedToHerbieBody companyName={company.name} />;
	} else if (company.latest_note) {
		header = <LatestNoteHeader note={company.latest_note} />;
		interaction = <LatestNoteBody note={company.latest_note} />;
	} else if (company.top_contact) {
		interaction = <KeyPerson person={company.top_contact} />;
	}

	return (
		<Body className={className}>
			{header}
			{interaction}
		</Body>
	);
}
