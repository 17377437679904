import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import styled from 'styled-components';

import { primaryDriveBlue } from '../../colors';
import type { EmployeeCount } from '../../components/employee-growth';

const TooltipContainer = styled.div`
	background-color: white;
	border: 1px solid #e0e0e0;
	padding: 6px 12px;
`;

const TooltipValue = styled('p')`
	color: ${(props) => props.color};
`;

type CustomTooltipProps = {
	readonly active: boolean;
	readonly label: number;
	readonly payload: Array<{
		stroke: string;
		value: number;
	}>;
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) =>
	!active ? null : (
		<TooltipContainer>
			<p>{moment(new Date(label)).format('LL')}</p>
			<TooltipValue color={payload[0].stroke}>
				{payload[0].value.toLocaleString()}{' '}
				{pluralize('employees', payload[0].value)}
			</TooltipValue>
		</TooltipContainer>
	);

const xAxisTickFormatter = (date: number) =>
	moment(new Date(date)).format("MMM 'YY");

interface Props {
	readonly domain: [number, number];
	readonly employees: Array<EmployeeCount>;
	readonly ticks: Array<number>;
}

export default class EmployeesChart extends React.Component<Props> {
	static hasData(employees: Array<EmployeeCount>): boolean {
		return employees.length > 0;
	}

	public override render() {
		const data = this.props.employees
			.map(({ employees, timestamp }) => ({
				employees,
				timestamp: moment(timestamp).valueOf(),
			}))
			.sort((a, b) => a.timestamp - b.timestamp);

		return (
			<ResponsiveContainer width="100%" height={200}>
				<LineChart data={data}>
					<XAxis
						dataKey="timestamp"
						domain={this.props.domain}
						interval="preserveStartEnd"
						tickFormatter={xAxisTickFormatter}
						ticks={this.props.ticks}
						type="number"
					/>
					<YAxis />
					<Tooltip content={CustomTooltip} />
					<CartesianGrid strokeDasharray="3 3" />
					<Line
						dataKey="employees"
						stroke={primaryDriveBlue.string()}
						type="monotone"
					/>
				</LineChart>
			</ResponsiveContainer>
		);
	}
}
