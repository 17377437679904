// @flow

import Downshift, { type DownshiftType } from 'downshift';
import { filter, match } from 'fuzzaldrin-plus';
import React from 'react';
import { connect } from 'react-redux';
import { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/fromPromise';
import styled from 'styled-components';

import { white } from '../colors';
import type { State as StoreState } from '../reducers';
import type { AlgoliaResponse } from '../search/behavior';
import { getAlgoliaKey } from '../selectors';
import search from '../utils/algolia';

import HighlightedCity from './highlighted-city';

type ItemType = string;
const DownshiftTyped: DownshiftType<ItemType> = Downshift;

const Container = styled.div`
	min-width: 400px;
	position: relative;
`;

const Dropdown = styled.ul`
	background-color: ${white.string()};
	border-radius: 0 0 5px 5px;
	box-shadow: 0 2px 3px 3px rgba(225, 225, 225, 0.6);
	max-height: 320px;
	overflow-y: scroll;
	position: absolute;
	width: 100%;
	z-index: 1;
`;

const Input = styled<{ isOpen?: boolean }, _>('input')`
	-webkit-appearance: none;
	background-color: ${white.string()};
	background-image: url('https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/ic_search.png');
	background-position: 8px 12px;
	background-repeat: no-repeat;
	background-size: 22px;
	border: none;
	${({ isOpen = false }) =>
		isOpen
			? `
			border-bottom: 1px solid #eee;
			border-radius: 5px 5px 0 0;
			box-shadow: 0 2px 3px 3px rgba(225,225,225,0.60);
			padding-bottom: 7px; // Offset border-bottom
		`
			: `
			border-bottom: 1px solid ${white.string()};
			border-radius: 5px;
			box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.50);
		`} font-size: inherit;
	outline: none;
	padding: 12px 10px 12px 36px;
	width: 100%;
`;

const Item = styled<{ highlighted?: boolean }, HTMLLIElement>('li')`
	display: grid;
	align-items: center;
	grid-gap: 6px;
	grid-template-columns: 16px auto;
	grid-template-areas: 'icon label';
	padding: 6px 10px;
	${({ highlighted = false }) =>
		highlighted ? 'background-color: #eee;' : ''} &:first-child {
		padding-top: 12px;
	}

	&:last-child {
		padding-bottom: 12px;
	}
`;

type OwnProps = {|
	city: ?string,
	onChange: (city: ?string) => void,
|};
type StateProps = {|
	searchKey: string,
|};
type Props = {|
	...OwnProps,
	...StateProps,
|};

type State = {
	cities: ?Array<string>,
};

let cachedCities: ?Array<string> = null;

const defaultCities = [
	'Ann Arbor, Michigan',
	'Chicago, Illinois',
	'Minneapolis, Minnesota',
	'New York, New York',
	'Pittsburgh, Pennsylvania',
	'San Francisco, California',
	'Washington, D.C.',
];

class CityPicker extends React.Component<Props, State> {
	_request: ?Subscription;

	state = {
		cities: cachedCities,
	};

	componentDidMount() {
		if (Array.isArray(this.state.cities)) return;

		this._request = Observable.fromPromise(
			search(this.props.searchKey, { facets: ['data.location'] }),
		)
			.map((response: AlgoliaResponse): Array<string> => {
				if (response.facets['data.location'] == null) {
					throw new Error(
						'Algolia failed to return facets for `data.location`.',
					);
				}

				return Object.keys(response.facets['data.location']);
			})
			.subscribe((cities: Array<string>): void => {
				cachedCities = cities;
				this.setState({ cities });
			});
	}

	render() {
		const { cities } = this.state;

		if (!Array.isArray(cities)) {
			return <p>Loading...</p>;
		}

		return (
			<DownshiftTyped
				defaultHighlightedIndex={0}
				initialInputValue={this.props.city || ''}
				onChange={this.props.onChange}
			>
				{({
					getInputProps,
					getItemProps,
					getMenuProps,
					getRootProps,
					highlightedIndex,
					isOpen,
					inputValue,
					selectedItem,
					toggleMenu,
				}) => (
					<Container {...getRootProps({})}>
						<Input
							{...getInputProps({
								isOpen,
								onFocus: () => {
									toggleMenu({
										inputValue: '',
									});
								},
								placeholder: 'Where are you going?',
							})}
						/>
						{isOpen ? (
							<Dropdown {...getMenuProps({})}>
								{(inputValue == null || inputValue === ''
									? defaultCities
									: filter(cities, inputValue)
								).map((item, index) => (
									<Item
										{...getItemProps({
											highlighted:
												highlightedIndex === index,
											item,
											selected: selectedItem === item,
										})}
										key={item}
									>
										{highlightedIndex === index && (
											<svg
												width="16"
												height="16"
												fill="#444"
												xmlns="http://www.w3.org/2000/svg"
												version="1.0"
												viewBox="0 0 56 100"
											>
												{/* TODO: Simplify this path */}
												<path
													d="
													M28,0C12,0,0,12,0,28S28,100,28,100s28-56,28-72S43,0,28,0z
													M28,40c-7,0-12-6-12-12c0-7,6-12,12-12c7,0,12,6,12,12
													C40,35,35,40,28,40z
												"
												/>
											</svg>
										)}
										<span style={{ gridArea: 'label' }}>
											<HighlightedCity
												match={
													inputValue == null
													|| inputValue === ''
														? []
														: match(
																item,
																inputValue,
															)
												}
											>
												{item}
											</HighlightedCity>
										</span>
									</Item>
								))}
							</Dropdown>
						) : null}
					</Container>
				)}
			</DownshiftTyped>
		);
	}
}

export default connect<_, OwnProps, _, _, _, _>(
	(state: StoreState): StateProps => ({
		searchKey: getAlgoliaKey(state),
	}),
	() => ({}),
)(CityPicker);
