import { colors, effects, fonts } from '@drivecapital/design-system';
import { IconButton } from '@drivecapital/design-system/components';
import {
	EyeIcon,
	EyeSlashOutlineIcon,
} from '@drivecapital/design-system/icons/human';
import { InformationLineIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback } from 'react';
import { OverlayArrow } from 'react-aria-components';
import styled from 'styled-components';

import type { User } from '../authentication';
import UnstyledTooltip, { UnstyledTooltipContent } from '../components/tooltip';

import type { TravelRecommendationStrategy, TravelUser } from './types';

const StrategyMap = {
	HIGH_BEAM_PIPELINE: {
		longForOther: (subject: string) =>
			`Companies in ${subject}'s high beam pipeline sorted by growth`,
		longForSelf: 'Companies in your high beam pipeline sorted by growth',
		short: 'Your High Beam Pipeline',
		shortForOther: 'High Beam Pipeline',
	},
	MARKET_MAP_GROWTH_COMPANIES: {
		longForOther: (subject: string) =>
			`The fastest growing companies in ${subject}'s active market maps`,
		longForSelf: 'The fastest growing companies in your active market maps',
		short: 'High Growth in your Market Maps',
		shortForOther: 'High Growth Market Map Companies',
	},
	OUTREACH_COMPANIES: {
		longForOther: (subject: string) =>
			`Companies that ${subject} has reached out to but haven't replied`,
		longForSelf: "Companies you've reached out to that haven't replied",
		short: 'Companies Not Responding',
		shortForOther: 'Companies Not Responding',
	},
	PREVIOUSLY_MET_COMPANIES: {
		longForOther: (subject: string) =>
			`Companies in this location ${subject} has met with before`,
		longForSelf: "Companies in this location you've met with before",
		short: 'Companies You’ve Met Before',
		shortForOther: 'Previously Met Companies',
	},
} satisfies Record<
	TravelRecommendationStrategy,
	{
		longForOther: (subject: string) => string;
		longForSelf: string;
		short: string;
		shortForOther: string;
	}
>;

const Explainer = styled.div<{ $hidden: boolean }>`
	${fonts.h4.expressive}
	color: ${(props) =>
		props.$hidden ? colors.text.secondary : colors.text.primary};
	display: grid;
	gap: 10px;
	grid-template-columns: auto auto 1fr;
	padding: 0 12px;

	button {
		align-items: center;
		display: flex;
	}

	svg {
		height: 24px;
		width: 24px;
	}
`;
const Tooltip = styled(UnstyledTooltip)`
	align-self: start;
	box-sizing: content-box;
`;
const TooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.hover}
	${fonts.paragraph.paragraph}
	background-color: ${colors.card.card};
	border-radius: 4px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 180px;
	padding: 8px;

	& .react-aria-OverlayArrow svg {
		display: block;
		fill: ${colors.card.card};
		stroke: ${colors.border.subtle};
	}
`;
const VisibilityButton = styled(IconButton)`
	justify-self: flex-end;
`;

interface Props {
	readonly authUser: User;
	readonly hidden: boolean;
	readonly onToggle: (strategy: TravelRecommendationStrategy) => void;
	readonly strategy: TravelRecommendationStrategy;
	readonly travelUser: TravelUser;
}

export default function RecommendationStrategyExplainer({
	authUser,
	hidden,
	onToggle,
	strategy,
	travelUser,
}: Props) {
	const description =
		travelUser.id === authUser.id
			? StrategyMap[strategy].longForSelf
			: StrategyMap[strategy].longForOther(travelUser.first_name);
	const title =
		travelUser.id === authUser.id
			? StrategyMap[strategy].short
			: StrategyMap[strategy].shortForOther;
	const handleToggle = useCallback(() => {
		onToggle(strategy);
	}, [onToggle, strategy]);

	return (
		<Explainer $hidden={hidden}>
			<span>
				{title}
				{hidden && ' (Hidden)'}
			</span>
			<Tooltip
				content={
					<TooltipContent placement="right">
						<OverlayArrow>
							<svg width={8} height={8} viewBox="0 0 8 8">
								<path d="M0 0 L4 4 L8 0" />
							</svg>
						</OverlayArrow>
						{description}
					</TooltipContent>
				}
			>
				<InformationLineIcon />
			</Tooltip>
			<VisibilityButton label="toggle-visibility" onClick={handleToggle}>
				{hidden ? <EyeIcon /> : <EyeSlashOutlineIcon />}
			</VisibilityButton>
		</Explainer>
	);
}
