import { colors, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { PhoneIcon } from '@drivecapital/design-system/icons/devices';
import { Person2Icon } from '@drivecapital/design-system/icons/human';
import {
	CiscoWebexIcon,
	GoogleMeetIcon,
	MicrosoftTeamsIcon,
	ZoomIcon,
} from '@drivecapital/design-system/icons/social';
import { CalendarIcon } from '@drivecapital/design-system/icons/time';
import { MapPinIcon } from '@drivecapital/design-system/icons/travel';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getEventDetails } from '../../profiles/timelines/calendar-event';
import { buildFromList } from '../../profiles/timelines/event-participant';
import getUniqueId from '../../utils/get-unique-id';
import type { TravelCompanyRecommendation } from '../types';

import LatestInteractionHeader from './latest-interaction-header';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
const Title = styled.div`
	${fonts.paragraph.strong};
	align-items: center;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-rows: auto;
	grid-template-columns: 1fr 18px auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	span {
		overflow: hidden;
		text-overflow: ellipsis;

		&:not(:first-child) {
			${fonts.paragraph.paragraph};
		}
	}

	svg {
		flex: 1;
		height: 16px;
		width: 16px;
	}
`;
const ParticipantsRow = styled.div`
	${fonts.paragraph.paragraph};
	align-items: center;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-rows: auto;
	grid-template-columns: 12px 1fr auto;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;

	div {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		color: ${colors.icon.primary};
		flex: 0 0 12px;
		height: 12px;
		width: 12px;
	}
`;
const LocationRow = styled.div`
	${fonts.paragraph.paragraph};
	align-items: center;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-rows: auto;
	grid-template-columns: 18px auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		flex: 1;
		height: 16px;
		width: 16px;
	}
`;

interface Props {
	readonly className?: string;
	readonly event: NonNullable<TravelCompanyRecommendation['latest_event']>;
}

export function LatestEventHeader({ event }: Props) {
	return (
		<LatestInteractionHeader
			chip={
				<Chip
					background={colors.data.dust.layer}
					color={colors.data.dust.textSecondary}
					icon={<CalendarIcon />}
					label="Calendar"
				/>
			}
			date={event.date}
		/>
	);
}

export function LatestEventBody({ className, event }: Props) {
	const eventId = useMemo(getUniqueId, [event]);
	const eventDetails = useMemo(
		() =>
			getEventDetails(
				event.conference_data,
				event.description,
				event.location,
			),
		[event],
	);
	let virtualEventIcon: [React.ReactNode, React.ReactNode] = [null, null];
	if (eventDetails.virtualMeetingLinks.zoom.length > 0) {
		virtualEventIcon = [<ZoomIcon key="zoom-icon" />, 'Zoom Meeting'];
	} else if (eventDetails.virtualMeetingLinks.google.length > 0) {
		virtualEventIcon = [<GoogleMeetIcon key="meet-icon" />, 'Google Meet'];
	} else if (eventDetails.virtualMeetingLinks.microsoft_teams.length > 0) {
		virtualEventIcon = [<MicrosoftTeamsIcon key="teams-icon" />, 'Teams'];
	} else if (eventDetails.virtualMeetingLinks.cisco_webex.length > 0) {
		virtualEventIcon = [<CiscoWebexIcon key="webex-icon" />, 'Webex'];
	}

	return (
		<Body className={className}>
			<Title>
				<span title={event.summary}>{event.summary}</span>
				{virtualEventIcon[0]}
				<span>{virtualEventIcon[1]}</span>
			</Title>
			<ParticipantsRow>
				<Person2Icon />
				<div>
					{buildFromList(
						event.participant_data.participants.slice(0, 3),
						eventId,
					)}
				</div>
			</ParticipantsRow>
			{eventDetails.physicalAddress && (
				<LocationRow>
					<MapPinIcon />
					<span>{eventDetails.physicalAddress}</span>
				</LocationRow>
			)}
			{!eventDetails.physicalAddress
				&& eventDetails.phoneNumbers.length > 0 && (
					<LocationRow>
						<PhoneIcon />
						<span>{eventDetails.phoneNumbers.join(', ')}</span>
					</LocationRow>
				)}
		</Body>
	);
}
