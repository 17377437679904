import { colors, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { EnvelopeIcon } from '@drivecapital/design-system/icons/document';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import EventParticipant, {
	buildFromList,
} from '../../profiles/timelines/event-participant';
import getUniqueId from '../../utils/get-unique-id';
import type { TravelCompanyRecommendation } from '../types';

import LatestInteractionHeader from './latest-interaction-header';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
const FromRow = styled.div`
	${fonts.paragraph.paragraph}
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
`;
const ToFromLabel = styled.span`
	color: ${colors.text.secondary};
`;
const RecipientsRow = styled.div`
	${fonts.paragraph.paragraph}
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
	justify-content: space-between;
	width: 100%;
`;

function latestEmail(
	emails: Props['emails'],
): Props['emails']['received'][0] | null {
	return (
		[...emails.received, ...emails.sent].toSorted((a, b) =>
			b.date.localeCompare(a.date),
		)[0] ?? null
	);
}

const MINIMUM_RECIPIENTS = 3;

interface Props {
	readonly className?: string;
	readonly emails: NonNullable<TravelCompanyRecommendation['latest_emails']>;
}

export function LatestEmailHeader({ emails }: Props) {
	const email = useMemo(() => latestEmail(emails), [emails]);

	if (!email) {
		throw new Error('Unexpected empty recent emails');
	}

	return (
		<LatestInteractionHeader
			chip={
				<Chip
					background={colors.data.dust.layer}
					color={colors.data.dust.textSecondary}
					icon={<EnvelopeIcon />}
					label="Email"
				/>
			}
			date={email.date}
		/>
	);
}

export function LatestEmailBody({ className, emails }: Props) {
	const email = useMemo(() => latestEmail(emails), [emails]);
	const visibleRecipients = (email?.recipient_data?.recipients ?? []).slice(
		0,
		MINIMUM_RECIPIENTS,
	);

	const recipientNames = useMemo(
		() =>
			buildFromList(
				visibleRecipients,
				`email-${email?.date ?? 'unknown'}`,
			),
		[email?.date, visibleRecipients],
	);

	if (!email) {
		throw new Error('Unexpected empty recent emails');
	}
	const recipientData = email.recipient_data;

	return (
		<Body className={className}>
			<FromRow>
				<ToFromLabel>From:</ToFromLabel>
				<EventParticipant
					{...email.sender}
					eventId={getUniqueId('email-sender')}
					tooltipPlace="top"
				/>
			</FromRow>
			<RecipientsRow>
				<div>
					<ToFromLabel>To:&nbsp;</ToFromLabel>
					{recipientNames}
					{!recipientData.all_included && (
						<span>, and others...</span>
					)}
				</div>
			</RecipientsRow>
		</Body>
	);
}
