import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	QuantityIndicator,
	Chip as UnstyledChip,
} from '@drivecapital/design-system/components';
import { uniqBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Tooltip, { UnstyledTooltipContent } from '../../components/tooltip';
import type { TravelCompanyRecommendation } from '../types';

const Body = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	gap: 4px;
	overflow-x: auto;
`;
const BodyScroll = styled.div`
	position: relative;

	&:before {
		box-shadow:
			inset 0px 0px 6px -12px ${colors.layer.layer},
			inset -18px 0px 6px -12px ${colors.layer.layer};
		content: '';
		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;
const Chip = styled(UnstyledChip)`
	flex: 0 0 auto;
`;
const TooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.shadow};
	${fonts.paragraph.paragraph};
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 4px;
	color: ${colors.text.secondary};
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px;
	width: 220px;
`;

const LIMIT = 2;

interface Props {
	readonly className?: string;
	readonly marketMaps: TravelCompanyRecommendation['market_maps'];
}

export default function MarketMaps({ className, marketMaps }: Props) {
	const uniqueMarketMaps = uniqBy(marketMaps, ({ id }) => id);

	if (uniqueMarketMaps.length === 0) return <div className={className} />;

	return (
		<BodyScroll className={className}>
			<Body>
				{uniqueMarketMaps.length > LIMIT && (
					<Tooltip
						content={
							<TooltipContent>
								{uniqueMarketMaps.map(({ id, name }) => (
									<span key={id}>{name}</span>
								))}
							</TooltipContent>
						}
					>
						<QuantityIndicator
							menu={false}
							onClick={() => {
								/* no-op */
							}}
							open={false}
							quantity={uniqueMarketMaps.length}
						/>
					</Tooltip>
				)}
				{uniqueMarketMaps.slice(0, LIMIT).map((marketMap) => (
					<Chip
						key={marketMap.id}
						background={colors.data.dust.layer}
						color={colors.text.secondary}
						label={marketMap.name}
					/>
				))}
			</Body>
		</BodyScroll>
	);
}
