import { colors } from '@drivecapital/design-system';
import {
	EmptyStatePlaceholder,
	ToastMessage,
	IconButton as UnstyledIconButton,
	useToastData,
} from '@drivecapital/design-system/components';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
} from '@drivecapital/design-system/icons/arrows';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import type { User } from '../authentication';
import { supportEmail } from '../config';
import { useSessionStorage } from '../utils/hooks/use-browser-storage';

import {
	useInfiniteTravelRecommendations,
	useTravelRecommendation,
	useTravelRecommendationFeedback,
} from './api';
import CompanyRecommendation, {
	CompanyRecommendationLoading,
} from './company-recommendation';
import FeedbackButton from './feedback-button';
import RecommendationStrategyExplainer from './recommendation-strategy-explainer';
import type { TravelRecommendationStrategy, TravelUser } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Buttons = styled.div`
	column-gap: 8px;
	display: grid;
	grid-template-columns: min-content min-content 1fr min-content min-content;
	justify-content: space-between;
`;
const IconButton = styled(UnstyledIconButton)`
	align-items: center;
	display: flex;

	&:not(:disabled) {
		cursor: pointer;
	}
`;
const NoRecommendations = styled(EmptyStatePlaceholder)`
	height: 250px;

	> a {
		color: ${colors.text.interactive};

		&:hover {
			color: ${colors.text.interactiveHover};
			text-decoration: underline;
		}
	}
`;

const BackButton = styled(IconButton)``;
const ForwardButton = styled(IconButton)``;

function EmptyRecommendations({
	authUser,
	hidden,
	onStrategyToggle,
	strategy,
	travelUser,
}: {
	readonly authUser: User;
	readonly hidden: boolean;
	readonly onStrategyToggle: (strategy: TravelRecommendationStrategy) => void;
	readonly strategy: TravelRecommendationStrategy;
	readonly travelUser: TravelUser;
}) {
	const handleClick = useCallback(() => {
		window.location.href = `mailto:${supportEmail}?subject=Travel Planner Feedback`;
	}, []);

	return (
		<Container>
			<RecommendationStrategyExplainer
				authUser={authUser}
				hidden={hidden}
				onToggle={onStrategyToggle}
				strategy={strategy}
				travelUser={travelUser}
			/>
			<NoRecommendations
				onPrimaryCallToAction={handleClick}
				primaryCallToAction="Let us know!"
			>
				No profiles found for this location.
				<br />
				Expecting something here?
			</NoRecommendations>
			<Buttons>
				<FeedbackButton helpful isDisabled />
				<FeedbackButton helpful={false} isDisabled />
				<div />
				<BackButton
					disabled
					label="previous-recommendation"
					size="small"
				>
					<ChevronLeftIcon />
				</BackButton>
				<ForwardButton
					disabled
					label="next-recommendation"
					size="small"
				>
					<ChevronRightIcon />
				</ForwardButton>
			</Buttons>
		</Container>
	);
}

interface Props {
	readonly authUser: User;
	readonly hidden: boolean;
	readonly location: string;
	readonly onStrategyToggle: (strategy: TravelRecommendationStrategy) => void;
	readonly pageSize: number;
	readonly strategy: TravelRecommendationStrategy;
	readonly travelUser: TravelUser;
}

export default function RecommendationsCard({
	authUser,
	hidden,
	location,
	onStrategyToggle,
	pageSize,
	strategy,
	travelUser,
}: Props) {
	const { alertStyle, toastData, setToastData } = useToastData(2000);
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useInfiniteTravelRecommendations({
			location,
			pageSize,
			recommendationStrategy: strategy,
			userId: travelUser.id,
			options: { enabled: !hidden },
		});

	const [cursor, setCursor] = useSessionStorage<number>(
		`travel-recommendations-${strategy}-${location}-${travelUser.id}`,
		0,
	);
	const recommendations = data ?? [];
	const entity = recommendations.at(cursor);

	const { data: recommendationData } = useTravelRecommendation({
		entityId: entity?.id ?? null,
		entityType: 'companies',
		location,
		recommendationStrategy: strategy,
		userId: travelUser.id,
	});
	const {
		mutate: submitFeedback,
		error: submitFeedbackError,
		isSuccess: submitFeedbackSuccess,
	} = useTravelRecommendationFeedback({
		entityId: entity?.id ?? null,
		entityType: 'companies',
		location,
		recommendationStrategy: strategy,
		userId: travelUser.id,
	});
	const handleFeedbackSubmit = useCallback(
		(feedback: string, helpful: boolean) => {
			if (!recommendationData?.id) return;

			const id = recommendationData.id;
			submitFeedback({ feedback, helpful, id });
		},
		[recommendationData, submitFeedback],
	);

	const handleNextClick = useCallback(() => {
		setCursor((prev) => Math.min(prev + 1, recommendations.length - 1));
	}, [recommendations.length, setCursor]);

	const handlePreviousClick = useCallback(() => {
		setCursor((prev) => Math.max(prev - 1, 0));
	}, [setCursor]);

	useEffect(() => {
		if (submitFeedbackError) {
			setToastData({
				headline: 'Error submitting feedback',
				message: 'Please try again later.',
				type: 'danger',
			});
		}
	}, [setToastData, submitFeedbackError]);

	useEffect(() => {
		if (submitFeedbackSuccess) {
			setToastData({
				headline: 'Feedback submitted',
				message: 'Thank you for the feedback!',
				type: 'success',
			});
		}
	}, [setToastData, submitFeedbackSuccess]);

	useEffect(() => {
		if (recommendations.length && cursor >= recommendations.length) {
			setCursor(recommendations.length - 1);
		}
	}, [cursor, recommendations.length, setCursor]);

	useEffect(() => {
		if (isLoading && cursor !== 0) {
			setCursor(0);
		}
	}, [cursor, isLoading, setCursor]);

	useEffect(() => {
		if (
			cursor >= recommendations.length - 3
			&& hasNextPage
			&& !isFetchingNextPage
		) {
			void fetchNextPage();
		}
	}, [
		cursor,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		recommendations.length,
	]);

	if (hidden) {
		return (
			<Container>
				<RecommendationStrategyExplainer
					authUser={authUser}
					hidden={hidden}
					onToggle={onStrategyToggle}
					strategy={strategy}
					travelUser={travelUser}
				/>
			</Container>
		);
	}

	if (!isLoading && recommendations.length === 0) {
		return (
			<EmptyRecommendations
				authUser={authUser}
				hidden={hidden}
				onStrategyToggle={onStrategyToggle}
				strategy={strategy}
				travelUser={travelUser}
			/>
		);
	}

	return (
		<Container>
			<ToastMessage
				headline={toastData?.headline ?? ''}
				message={toastData?.message ?? ''}
				style={alertStyle}
				type={toastData?.type ?? 'info'}
			/>
			<RecommendationStrategyExplainer
				authUser={authUser}
				hidden={hidden}
				onToggle={onStrategyToggle}
				strategy={strategy}
				travelUser={travelUser}
			/>
			{entity == null ? (
				<CompanyRecommendationLoading />
			) : (
				<CompanyRecommendation authUser={authUser} company={entity} />
			)}
			<Buttons>
				<FeedbackButton
					helpful
					isDisabled={entity == null}
					onSubmit={handleFeedbackSubmit}
				/>
				<FeedbackButton
					helpful={false}
					isDisabled={entity == null}
					onSubmit={handleFeedbackSubmit}
				/>
				<div />
				<BackButton
					disabled={cursor === 0 || isLoading}
					label="previous-recommendation"
					onClick={handlePreviousClick}
					size="small"
				>
					<ChevronLeftIcon />
				</BackButton>
				<ForwardButton
					disabled={
						!hasNextPage && cursor === recommendations.length - 1
					}
					label="next-recommendation"
					onClick={handleNextClick}
					size="small"
				>
					<ChevronRightIcon />
				</ForwardButton>
			</Buttons>
		</Container>
	);
}
