import { colors } from '@drivecapital/design-system';
import { SignalIndicator } from '@drivecapital/design-system/components';
import { Temporal } from '@js-temporal/polyfill';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ReactNodeColumn from '../table/columns/react-node';
import { sortDirections } from '../table/sort';

const Growth = styled.div`
	align-items: center;
	display: flex;
	padding: 4px 0;
`;
const Change = styled.span<{ $direction: 'POSITIVE' | 'NEGATIVE' }>`
	color: ${({ $direction }) => {
		if ($direction === 'POSITIVE') {
			return colors.data.green.textSecondary;
		} else {
			return colors.data.red.textSecondary;
		}
	}};
	padding-left: 4px;
`;

export interface EmployeeCount {
	employees: number | string;
	source: string;
	timestamp: string;
}

// As of Sept '23:
// herbie_production=> select
//   min(growth_score) as minimum,
//   percentile_cont(0.25) within group (order by growth_score) as twenty_fifth_percentile,
//   percentile_cont(0.5) within group (order by growth_score) as fiftyith_percentile,
//   percentile_cont(0.75) within group (order by growth_score) as seventy_fifth_percentile,
//   max(growth_score) as maxium
// from contacts_fusedcompany where growth_score is distinct from null;
//       minimum       | twenty_fifth_percentile | fiftyith_percentile | seventy_fifth_percentile |       maxium
// --------------------+-------------------------+---------------------+--------------------------+--------------------
//  -38.33329987168941 |      -0.068931328018879 |  0.0640826873385013 |       0.3860819624735251 | 21.151107535941495
//
// To decide whether a company's growth is interesting, we'll check "below 25th percentile" and "above 75th percentile"
const INTERESTING_GROWTH_SCORE_LOWER_BOUND = -0.0689;
const INTERESTING_GROWTH_SCORE_UPPER_BOUND = 0.386;
const INTERESTING_GROWTH_RATE_LIMIT = 10;

const RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_START = 16;
const RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_END = 10;
/**
 * Approximates a company's head count growth within the last year.
 * Calculation: Percentage change between newest head count and the
 * average head count between RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_END and RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_START
 * months ago
 */
const calculateRelativeGrowth = (
	employeeCounts: Props['recentHeadcounts'],
): number => {
	const current = employeeCounts.at(-1);
	if (!current) return 0;

	const lookbackRangeStart = Temporal.Now.plainDateISO().subtract({
		months: RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_START,
	});
	const lookbackRangeStop = Temporal.Now.plainDateISO().subtract({
		months: RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_END,
	});

	const countsWithinLookback = employeeCounts.filter(({ timestamp }) => {
		const stamp = Temporal.PlainDate.from(timestamp);
		return (
			Temporal.PlainDate.compare(lookbackRangeStart, stamp) <= 0
			&& Temporal.PlainDate.compare(stamp, lookbackRangeStop) <= 0
		);
	});

	if (countsWithinLookback.length === 0) {
		return 0;
	}

	const avgCount =
		countsWithinLookback
			.map(({ employees }) =>
				typeof employees === 'string'
					? parseInt(employees, 10)
					: employees,
			)
			.reduce((prev, curr) => prev + curr, 0)
		/ countsWithinLookback.length;
	const currentCount =
		typeof current.employees === 'string'
			? parseInt(current.employees, 10)
			: current.employees;

	if (avgCount === 0) {
		return 0;
	}

	return ((currentCount - avgCount) / avgCount) * 100;
};

function hasSignificantSignal(
	employeeGrowthScore: Props['employeeGrowthScore'],
	relativeGrowth: number,
) {
	return (
		employeeGrowthScore != null
		&& (employeeGrowthScore <= INTERESTING_GROWTH_SCORE_LOWER_BOUND
			|| employeeGrowthScore >= INTERESTING_GROWTH_SCORE_UPPER_BOUND)
		&& Math.abs(relativeGrowth) >= INTERESTING_GROWTH_RATE_LIMIT
	);
}

function parseHeadcount(employeeCounts: Props['recentHeadcounts']) {
	const lookbackRangeStart = Temporal.Now.plainDateISO().subtract({
		months: RELATIVE_GROWTH_LOOKBACK_RANGE_MONTHS_START,
	});

	const all = (employeeCounts || []).map(({ timestamp, ...rest }) => ({
		...rest,
		timestamp,
		timestampEpochSeconds:
			Temporal.PlainDateTime.from(timestamp).toZonedDateTime('UTC')
				.epochSeconds,
	}));
	const recent = all.filter(
		({ timestamp }) =>
			Temporal.PlainDate.compare(
				lookbackRangeStart,
				Temporal.PlainDate.from(timestamp),
			) <= 0,
	);

	return [all, recent];
}

export function useHeadcounts(employeeCounts: Props['recentHeadcounts']) {
	const [allHeadcounts, recentHeadcounts] = useMemo(() => {
		return parseHeadcount(employeeCounts);
	}, [employeeCounts]);

	return [allHeadcounts, recentHeadcounts];
}

interface Props {
	readonly className?: string;
	readonly employeeGrowthScore: number | null;
	readonly recentHeadcounts: Array<Omit<EmployeeCount, 'source'>>;
	readonly label?: string;
}

export default function EmployeeGrowth({
	className,
	employeeGrowthScore,
	recentHeadcounts,
	label = '(prev yr)',
}: Props) {
	const relativeGrowth = useMemo(
		() => calculateRelativeGrowth(recentHeadcounts),
		[recentHeadcounts],
	);

	const growthDirection = relativeGrowth > 0 ? 'POSITIVE' : 'NEGATIVE';
	const showSignalIndicator = hasSignificantSignal(
		employeeGrowthScore,
		relativeGrowth,
	);

	if (!showSignalIndicator) {
		return null;
	}

	return (
		<Growth className={className}>
			<SignalIndicator direction={growthDirection} icon="TREND" />
			<Change $direction={growthDirection}>
				{numeral(relativeGrowth).format('0')}% {label}
			</Change>
		</Growth>
	);
}

function getRelativeGrowth(
	employeeGrowthScore: number | null,
	employees: Array<Omit<EmployeeCount, 'source'>>,
) {
	const [, recentHeadcounts] = parseHeadcount(employees);
	const relativeGrowth = calculateRelativeGrowth(recentHeadcounts);

	if (hasSignificantSignal(employeeGrowthScore, relativeGrowth)) {
		return relativeGrowth;
	}

	return null;
}

interface HasEmployeeData {
	employee_counts: Array<EmployeeCount>;
	growth_score: number | null;
}

export class EmployeeGrowthColumn<
	T extends HasEmployeeData,
> extends ReactNodeColumn<T> {
	constructor({ name = 'Employee Growth' }: { name?: string } = {}) {
		super({
			name,
			initialSortDirection: sortDirections.descending,
			select: ({ employee_counts, growth_score }) => (
				<EmployeeGrowth
					employeeGrowthScore={growth_score}
					recentHeadcounts={employee_counts}
				/>
			),
			sort: (direction, recA, recB) => {
				const growthA = getRelativeGrowth(
					recA.growth_score,
					recA.employee_counts,
				);
				const growthB = getRelativeGrowth(
					recB.growth_score,
					recB.employee_counts,
				);

				if (growthA === null && growthB === null) {
					return 0;
				}
				if (growthA === null) {
					return 1;
				}
				if (growthB === null) {
					return -1;
				}
				if (direction === 'ascending') {
					return growthA - growthB;
				}
				return growthB - growthA;
			},
		});
	}
}
