import { colors, effects } from '@drivecapital/design-system';
import {
	TextInput,
	Button as ThemeButton,
} from '@drivecapital/design-system/components';
import {
	EmojiFrownIcon,
	EmojiSmileIcon,
} from '@drivecapital/design-system/icons/human';
import React, { useCallback, useState } from 'react';
import {
	Button as AriaButton,
	Popover as AriaPopover,
	DialogTrigger,
} from 'react-aria-components';
import styled from 'styled-components';

const Button = styled(AriaButton)<{ $helpful: boolean }>`
	background: ${(props) =>
		props.$helpful
			? colors.data.green.layerSubtle
			: colors.data.red.layerSubtle};
	border: 1px solid
		${(props) =>
			props.$helpful ? colors.data.green.border : colors.data.red.border};
	border-radius: 6px;
	color: ${(props) =>
		props.$helpful ? colors.data.green.border : colors.data.red.border};
	cursor: pointer;
	display: flex;
	margin: 0;
	outline: none;
	padding: 4px 8px;

	> svg {
		height: 18px;
		width: 18px;
	}

	&:hover {
		border-color: ${(props) =>
			props.$helpful
				? colors.data.green.borderHover
				: colors.data.red.borderHover};
		color: ${(props) =>
			props.$helpful
				? colors.data.green.borderHover
				: colors.data.red.borderHover};
	}

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}

	&:disabled {
		background: ${colors.layer.layer};
		border-color: ${colors.icon.disabled};
		color: ${colors.icon.disabled};
		cursor: not-allowed;
	}
`;
const Popover = styled(AriaPopover)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	outline: none;
	overflow: hidden;
	padding: 8px;
`;
const FeedbackForm = styled.form`
	align-items: center;
	display: flex;
	gap: 8px;
`;

interface Props {
	readonly helpful: boolean;
	readonly isDisabled: boolean;
	readonly onSubmit?: (feedback: string, helpful: boolean) => void;
}

export default function FeedbackButton({
	helpful,
	isDisabled,
	onSubmit,
}: Props) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleFeedbackSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			const formData = new FormData(event.currentTarget);
			const feedback = formData.get('feedback') as string; // this assertion is safe as long as the input is required

			onSubmit?.(feedback, helpful);
			setDialogOpen(false);
		},
		[helpful, onSubmit],
	);

	return (
		<DialogTrigger isOpen={dialogOpen} onOpenChange={setDialogOpen}>
			<Button $helpful={helpful} isDisabled={isDisabled}>
				{helpful ? <EmojiSmileIcon /> : <EmojiFrownIcon />}
			</Button>
			<Popover placement="bottom start">
				<FeedbackForm onSubmit={handleFeedbackSubmit}>
					<TextInput
						autoFocus
						name="feedback"
						placeholder={`What did we get ${helpful ? 'right' : 'wrong'}?`}
						required
					/>
					<ThemeButton type="submit">Submit</ThemeButton>
				</FeedbackForm>
			</Popover>
		</DialogTrigger>
	);
}
