import moment from 'moment';
import numeral from 'numeral';
import React from 'react';

type Props = {
	readonly current: { employees: number; timestamp: string };
};

export default function Employees({ current }: Props): JSX.Element {
	return (
		<span>
			{numeral(current.employees).format('0,0')}
			{' (as of '}
			{moment(current.timestamp).format("MMMM DD 'YY")})
		</span>
	);
}
