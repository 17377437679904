import { colors } from '@drivecapital/design-system';
import { ControlledComboBox } from '@drivecapital/design-system/components';
import { match } from 'fuzzaldrin-plus';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { User } from '../authentication';
import {
	type AutoCompleteItem,
	type AutoCompleteOptions,
	UncontrolledAutoCompleteInput,
} from '../locations';

import { useTravelUsers } from './api';
import type { TravelUser } from './types';

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
`;
const UserComboBox: typeof ControlledComboBox = styled(ControlledComboBox)`
	flex: 1;
`;
const LocationComboBoxWrapper = styled.div`
	flex: 1;
`;
const ComboBoxItemContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
`;
const ComboBoxItemPhoto = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	height: 20px;
	object-fit: cover;
	width: 20px;
`;

interface ComboBoxUser extends TravelUser {
	readonly text: string;
}

function authenticatedUserText(firstName: string, lastName: string): string {
	return `${firstName} ${lastName} (You)`;
}

function travelUserToComboBoxUser(
	user: TravelUser,
	authenticatedUserId: number,
): ComboBoxUser {
	return {
		...user,
		text:
			user.id === authenticatedUserId
				? authenticatedUserText(user.first_name, user.last_name)
				: `${user.first_name} ${user.last_name}`,
	};
}

const autoCompleteOptions: AutoCompleteOptions = {
	excludedLocationTypes: [
		'ADDRESS',
		'COUNTRY',
		'CUSTOM_LOCATION',
		'STATE_PROVINCE',
	],
	prioritizedCountryCodes: ['CAN', 'USA'],
	suggestions: [
		{
			id: 'Atlanta, Georgia',
			name: 'Atlanta, Georgia',
			type: 'CITY',
		},
		{
			id: 'Austin, Texas',
			name: 'Austin, Texas',
			type: 'CITY',
		},
		{
			id: 'Boulder, Colorado',
			name: 'Boulder, Colorado',
			type: 'CITY',
		},
		{
			id: 'Chicago, Illinois',
			name: 'Chicago, Illinois',
			type: 'CITY',
		},
		{
			id: 'Toronto, Ontario',
			name: 'Toronto, Ontario',
			type: 'CITY',
		},
	],
};

function ComboBoxItem({
	first_name,
	last_name,
	photo_url,
	text,
}: ComboBoxUser) {
	return (
		<ComboBoxItemContainer>
			<ComboBoxItemPhoto
				alt={`${first_name} ${last_name} profile`}
				src={photo_url}
			/>
			<span>{text}</span>
		</ComboBoxItemContainer>
	);
}

interface Props {
	readonly authenticatedUser: User;
	readonly defaultLocation: string;
	readonly onLocationSelect: (name: string | null) => void;
	readonly onUserSelect: (userId: User['id'] | null) => void;
	readonly selectedUserId: User['id'] | null;
}

export default function PickerHeader({
	authenticatedUser,
	defaultLocation,
	onLocationSelect,
	onUserSelect,
	selectedUserId,
}: Props) {
	const handleLocationSelect = useCallback(
		(location: AutoCompleteItem | null) => {
			onLocationSelect(location?.name ?? null);
		},
		[onLocationSelect],
	);

	const { data: travelUsers, isLoading: isUsersLoading } = useTravelUsers();
	const userComboBoxItems = useMemo<Array<ComboBoxUser>>(
		() =>
			(travelUsers ?? [])
				.map((user) =>
					travelUserToComboBoxUser(user, authenticatedUser.id),
				)
				.toSorted((a, b) => {
					if (a.id === authenticatedUser.id) {
						return -1;
					} else if (b.id === authenticatedUser.id) {
						return 1;
					} else {
						return 0;
					}
				}),
		[authenticatedUser.id, travelUsers],
	);
	const defaultUserComboxItems = useMemo(
		() =>
			userComboBoxItems.filter(
				({ id, personas }) =>
					personas.includes('INVESTOR')
					|| id === authenticatedUser.id,
			),
		[authenticatedUser, userComboBoxItems],
	);
	const handleUserComboBoxSelect = useCallback(
		(user: ComboBoxUser | null) => {
			onUserSelect(user?.id ?? null);
		},
		[onUserSelect],
	);
	const userComboBoxFilter = useCallback(
		(user: ComboBoxUser, query: string) => {
			return match(user.text, query).length > 0;
		},
		[],
	);
	const defaultInputValue = useMemo(() => {
		if (selectedUserId === authenticatedUser.id) {
			return authenticatedUserText(
				authenticatedUser.first_name,
				authenticatedUser.last_name,
			);
		} else {
			return '';
		}
	}, [authenticatedUser, selectedUserId]);
	const [userInputValue, setUserInputValue] = useState(defaultInputValue);

	useEffect(() => {
		setUserInputValue(defaultInputValue);
	}, [defaultInputValue]);

	useEffect(() => {
		if (selectedUserId && travelUsers) {
			const selectedTravelUser = travelUsers.find(
				(user) => user.id === selectedUserId,
			);

			if (selectedTravelUser) {
				setUserInputValue(
					travelUserToComboBoxUser(
						selectedTravelUser,
						authenticatedUser.id,
					).text,
				);
			}
		}
	}, [authenticatedUser, selectedUserId, travelUsers]);

	return (
		<Container>
			<LocationComboBoxWrapper>
				<UncontrolledAutoCompleteInput
					ariaLabel="travel-locations-picker"
					autoFocus={defaultLocation === ''}
					clearOnSelect={false}
					defaultQuery={defaultLocation}
					htmlId="travel-locations-picker"
					label="Where to?"
					onSelect={handleLocationSelect}
					options={autoCompleteOptions}
					placeholder="Search for a location"
				/>
			</LocationComboBoxWrapper>
			<UserComboBox
				defaultItems={defaultUserComboxItems}
				filterItem={userComboBoxFilter}
				id="travel-users-picker"
				inputValue={userInputValue}
				isDisabled={isUsersLoading}
				items={userComboBoxItems}
				label="Who is traveling?"
				onInputValueChange={setUserInputValue}
				onSelect={handleUserComboBoxSelect}
				placeholder={
					isUsersLoading ? 'loading...' : 'Search for a user'
				}
				renderItem={ComboBoxItem}
			/>
		</Container>
	);
}
