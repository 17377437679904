// @flow

import listify from '@drivecapital/listify';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import numeral from 'numeral';
import pluralize from 'pluralize';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as colors from '../colors';
import ExternalLink from '../components/external-link';
import Employees from '../profiles/employees';
import { Info, InfoList } from '../profiles/info/info';
import Section from '../profiles/section';
import EmployeesSection from '../profiles/sections/employees';
import get from '../utils/get';
import orArray from '../utils/or-array';

import type { Explanation, SuggestionData } from './types';

const moment = extendMoment(Moment);

const getReasonExplanation = (reason: Explanation) => {
	switch (reason.type) {
		case 'bod':
			return 'On our Diverse BOD WatchList';
		case 'growth':
			return 'Growth';
		case 'market_maps':
			return `${reason.name} Market Map`;
		case 'other_one_pagers':
			return `${reason.author} wrote a One Pager ${moment(
				reason.date,
			).fromNow()}`;
		case 'other_notes':
			return `Other people have written ${pluralize(
				'notes',
				reason.count,
				true,
			)}`;
		case 'overdrive_active':
			return 'Active on your High Beam pipeline';
		case 'overdrive_other_snoozed':
			return "Snoozed on someone else's High Beam pipeline";
		case 'overdrive_own_snoozed':
			return 'Snoozed on your High Beam pipeline';
		case 'own_event_recency':
			return 'You have met with them in the past';
		case 'own_notes':
			return `You have written ${pluralize('notes', reason.count, true)}`;
		case 'own_one_pagers':
			return `You wrote a One Pager ${moment(reason.date).fromNow()}`;
		case 'watch_lists':
			return `On your ${reason.name} Watch List`;
		default:
			return reason.type;
	}
};

function parseHostname(url: string): string {
	try {
		return new URL(url).hostname;
	} catch {
		return url;
	}
}

const AboutSection = styled.div`
	display: grid;
	grid-template-columns: auto minmax(min-content, 2fr) minmax(
			min-content,
			1fr
		);
	grid-gap: 12px;
`;

const DataSection = styled.div`
	flex-grow: 1;
`;

const Icon = styled.img`
	height: 36px;
	margin: 12px 16px 8px 0px;
	width: 36px;
`;

const Name = styled.h1`
	clear: left;
	color: ${colors.charcoalGray.string()};
	font-size: 2.2em;
	font-weight: normal;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Reasons = styled.div`
	display: flex;
`;

const Photo = styled.img`
	background-color: white;
	border: 1px solid rgb(212, 212, 212);
	height: 180px;
	object-fit: contain;
	object-position: center;
	width: 180px;
`;

const SuggestionInfo = styled(Info)`
	margin-left: 0;
`;

const SuggestionWrapper = styled.div``;

const defaultDriveIconPath =
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/new-icons/colored-backgrounds/';

const typeToIconTranslate = {
	bod: 'drive',
	growth: 'people',
	market_maps: 'market',
	other_one_pagers: 'bookmark',
	other_notes: 'note1',
	overdrive_active: 'lol-final-pin-icon',
	overdrive_other_snoozed: 'lol-final-pin-icon',
	overdrive_own_snoozed: 'lol-final-pin-icon',
	own_event_recency: 'calendar',
	own_notes: 'note1',
	own_one_pagers: 'bookmark',
	watch_lists: 'docstar',
};

type Props = {
	suggestion: SuggestionData,
};

const Suggestion = ({ suggestion, ...props }: Props) => {
	const employeeCounts = orArray(suggestion.entity.employee_counts);

	const employeeDates = employeeCounts
		.map(get('timestamp'))
		.map((date) => moment(date));

	const domain = [
		moment(Math.min(+new Date(), ...employeeDates))
			.startOf('quarter')
			.toDate()
			.getTime(),
		moment(Math.max(0, ...employeeDates))
			.add(1, 'quarter')
			.startOf('quarter')
			.toDate()
			.getTime(),
	];

	const ticks = Array.from(moment.range(domain).by('quarter')).map((m) =>
		m.toDate().getTime(),
	);

	const formattedEmployeeCounts = employeeCounts.map(
		({ employees, timestamp }) => ({
			employees:
				typeof employees === 'number'
					? employees
					: parseInt(employees, 10),
			timestamp,
		}),
	);

	const homepage_url: ?string = suggestion.entity.homepage_url;

	return (
		<SuggestionWrapper {...props}>
			<DataSection>
				<Section>
					<AboutSection>
						<Link
							to={`/${suggestion.entity.type}/${suggestion.entity.id}`}
						>
							<Photo
								alt={suggestion.id}
								src={suggestion.entity.logo_url}
							/>
						</Link>
						<div>
							<Name>{suggestion.entity.name}</Name>
							{!!homepage_url && (
								<ExternalLink href={homepage_url}>
									{parseHostname(homepage_url)}
								</ExternalLink>
							)}
							{!!suggestion.entity.description && (
								<p>{suggestion.entity.description}</p>
							)}
							{suggestion.explanation.length > 0 && (
								<Reasons>
									{suggestion.explanation.map((reason, i) => (
										<Icon
											alt={getReasonExplanation(reason)}
											data-tip={getReasonExplanation(
												reason,
											)}
											// This list is static, so using the index won't break anything.
											// eslint-disable-next-line react/no-array-index-key
											key={i}
											src={`${defaultDriveIconPath}${
												typeToIconTranslate[reason.type]
											}.png`}
										/>
									))}
								</Reasons>
							)}
						</div>
						<InfoList>
							{formattedEmployeeCounts.length >= 1 ? (
								<SuggestionInfo title="Employees">
									<Employees
										previous={
											formattedEmployeeCounts[
												formattedEmployeeCounts.length
													- 2
											]
										}
										current={
											formattedEmployeeCounts[
												formattedEmployeeCounts.length
													- 1
											]
										}
									/>
								</SuggestionInfo>
							) : (
								Array.isArray(suggestion.entity.employee_counts)
								&& suggestion.entity.employee_counts.length
									> 0 && (
									<SuggestionInfo title="Employees">
										{
											suggestion.entity.employee_counts[0]
												.employees
										}
										{' (as of '}
										{moment(
											suggestion.entity.employee_counts[0]
												.timestamp,
										).format("MMMM DD 'YY")}
										{')'}
									</SuggestionInfo>
								)
							)}
							{!!suggestion.entity.founded && (
								<SuggestionInfo title="Founded">
									{moment(suggestion.entity.founded).year()}
								</SuggestionInfo>
							)}
							{!!suggestion.entity.funding && (
								<SuggestionInfo title="Total funding">
									{numeral(suggestion.entity.funding)
										.format('$0,0a')
										.toUpperCase()}
								</SuggestionInfo>
							)}
							{!!suggestion.entity.estimated_out_of_cash_date && (
								<SuggestionInfo title="Est. out of cash">
									{moment(
										suggestion.entity
											.estimated_out_of_cash_date,
									).format("MMMM 'YY")}
								</SuggestionInfo>
							)}
							{!!suggestion.entity.market_maps
								&& suggestion.entity.market_maps.length > 0 && (
									<SuggestionInfo title="Market maps">
										{listify(suggestion.entity.market_maps)}
									</SuggestionInfo>
								)}
							{!!suggestion.entity.last_contact_with_drive && (
								<SuggestionInfo title="Last contact">
									<span
										title={moment(
											suggestion.entity
												.last_contact_with_drive,
										).format('LLLL')}
									>
										{moment(
											suggestion.entity
												.last_contact_with_drive,
										).fromNow()}
									</span>
								</SuggestionInfo>
							)}
						</InfoList>
					</AboutSection>
				</Section>
				{suggestion.entity.type === 'companies' && (
					<EmployeesSection
						domain={domain}
						employees={employeeCounts || null}
						key={suggestion.id}
						ticks={ticks}
					/>
				)}
			</DataSection>
		</SuggestionWrapper>
	);
};

export default Suggestion;
